import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useModelStore } from './model'
import iconWelcome from '../assets/desktop/handshake.svg'
import iconGeneralData from '../assets/desktop/fabrik.svg'
import iconMoreLocations from '../assets/desktop/ort.svg'
import iconLinks from '../assets/desktop/netz.svg'
import iconCompanyArticle from '../assets/desktop/company-article.svg'
import iconProductArticle from '../assets/desktop/search.svg'
import iconFinish from '../assets/desktop/finish.svg'

export const useNavigationStore = defineStore('navigation', () => {
  const router = useRouter()
  const model = useModelStore()

  const items = ref([
    { name: 'Willkommen', active: false, valid: false, available: false, value: '', icon: '' },
    { name: 'Allgemeine Unternehmensdaten', active: false, valid: false, available: false, value: '', icon: '' },
    { name: 'weitere Standorte', active: false, valid: false, available: false, value: '', icon: '', },
    { name: 'Social Media & Links', active: false, valid: false, available: false, value: '', icon: '', },
    { name: 'Firmendaten', active: false, valid: false, available: false, value: '', icon: '', },
    { name: 'Produktdaten', active: false, valid: false, available: false, value: '', icon: '', },
    { name: 'Fertigstellung und absenden', active: false, valid: false, available: false, value: '', icon: '', },
  ])

  function step(s) {
    items.value.forEach(function (value, i) {
      // console.log('%d: %s', i, value);
      value.active = i == s
    })
    updateState()
  }

  function updateState() {
    // check for each state if this state can be navigated to (is valid)
    // abgeschaltet weil sonst ein model ohne Id erstellt wird, da sie in der url fehlt
    items.value[0].available = false
    items.value[0].value = 'welcome'
    items.value[0].icon = iconWelcome

    items.value[1].available = true
    items.value[1].value = 'generaldata'
    items.value[1].icon = iconGeneralData

    items.value[2].available = true
    items.value[2].value = 'morelocations'
    items.value[2].icon = iconMoreLocations

    items.value[3].available = true
    items.value[3].value = 'socialmedialinks'
    items.value[3].icon = iconLinks

    items.value[4].available = true
    items.value[4].value = 'companyarticle'
    items.value[4].icon = iconCompanyArticle

    items.value[5].available = true
    items.value[5].value = 'productarticle'
    items.value[5].icon = iconProductArticle

    items.value[6].available = true
    items.value[6].value = 'finishpage'
    items.value[6].icon = iconFinish
  }

  function navigate(i) {
    //console.log(i);
    if (i == -1) router.push({ name: 'home' })
    if (i == 0) router.push({ name: 'welcome' })
    if (i == 1) router.push({ name: 'generaldata' })
    if (i == 2) router.push({ name: 'morelocations' })
    if (i == 3) router.push({ name: 'socialmedialinks' })
    if (i == 4) router.push({ name: 'companyarticle' })
    if (i == 5) router.push({ name: 'productarticle' })
    if (i == 6) router.push({ name: 'finishpage' })

    model.setProgress(i)
  }

  return { items, step, navigate, updateState }
})
