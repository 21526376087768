<template>
    <button type="button" class="del-btn">{{ this.title }}
        <img v-if="hasImg" :src="this.imageString" alt="Logo" />
    </button>
</template>

<script>
export default {
    props: {
        title: { type: String, default: "" },
        hasImg: { type: Boolean, default: false },
        imageString: { type: String, default: "" }
    },
    data() {
        return {};
    },
}
</script>

<style scoped>
.del-btn {
    width: auto;
    border: solid 1px var(--mdblue);
    background: var(--mdblue);
    color: #fff;
    font-family: Roboto-Regular;
    border-radius: 3px;
    font-size: 1rem;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    padding: 0 6px;
    cursor: pointer;
    will-change: auto;
    box-shadow: 3px 3px 10px var(--light-gray);
    -webkit-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
}
</style>