<template>
  <base-card v-if="!this.fatalError" class="base-card" title="Über Uns" :hasPreview="true" @post="postDataPreview()"
    :hasTitle="true" :logo="imageCompanyData">
    <div class="company-article-container">
      <div class="input-container">
        <form>
          <input-field :id="inputFields[1].id" :title="this.inputFields[1].title" :value="this.inputFields[1].value"
            :type="this.inputFields[1].type" :isRequired="this.inputFields[1].isRequired" :stageId="4"
            :listId="this.instanceId">
          </input-field>
        </form>
      </div>
      <text-editor :stageId="stageNumber" :listId="this.instanceId" :id="this.inputFields[2].id" title="Beschreibung:"
        :value="this.inputFields[2].value"></text-editor>
        <!-- <VueQuill :stageId="stageNumber" :listId="this.instanceId" :propId="this.inputFields[2].id" title="Beschreibung:"
        :value="this.inputFields[2].value"></VueQuill> -->
      <div class="upload-container">
        <Upload :ueberschrift="'Upload Firmenbild:'" :stageId="stageNumber" :value="this.inputFields[3].value"
          :listId="this.instanceId" :id="4" :file="this.file" />
      </div>
      <div class="button-container">
        <!-- <one-button title="Artikel hinzufügen" @click="IdPlusEins()"></one-button> -->
        <one-button title="Weiter/Überspringen" type="button" v-on:click.prevent="postDataNextStep()"></one-button>
      </div>
    </div>
  </base-card>
  <h1 v-if="fatalError">Keine Daten gefunden!</h1>
</template>

<script>
import InputField from "../components/BasicComponents/InputField.vue";
import OneButton from "../components/BasicComponents/OneButton.vue";
import { useNavigationStore } from '../stores/navigation'
import TextEditor from "../components/BasicComponents/TextEditor.vue";
import Upload from "../components/FileUpload/Upload.vue";
import BaseCard from '../components/BasicComponents/BaseCard.vue'
import imageCompanyData from '../assets/desktop/company-article.svg'
import { useModelStore } from '../stores/model'
import { ApiNames, httpGet, httpPost } from '../libs/httpHelper'

const stageNumber = 4;

export default {
  components: { InputField,  OneButton, Upload, BaseCard, TextEditor },//,  VueQuill
  data() {
    return {
      imageCompanyData: imageCompanyData,
      navigationStore: useNavigationStore(),
      modelStore: useModelStore(),
      instanceId: 0,
      stageNumber: stageNumber,
      file: {},
      fatalError: false,
      inputFields: [
        {
          id: 1,
          title: "Überschrift",
          type: "text",
          value: "Über Uns",
          isRequired: false
        },
        {
          id: 2,
          title: "Link",
          type: "url",
          value: "",
          isRequired: false
        },
        {
          id: 3,
          title: "Editor",
          type: "text",
          value: "",
          isRequired: false
        }, {
          id: 4,
          title: "UploadImage",
          type: "text",
          value: "",
          isRequired: false
        }
      ]
    };
  },
  beforeMount() {
    if (!this.modelStore.model) {
      this.fatalError = true
    }
    if (!this.modelStore.checkValidModel())
      this.fatalError = true

    var currStage = this.modelStore.getStage(stageNumber)
    //this.modelStore.setNextInstance(stageNumber)
    this.instanceId = currStage.currentInstance
     if (currStage) {
      this.ShowListParams(this.instanceId)
    //   if (this.instanceId < 2) {
    //     this.modelStore.fillStageParams(stageNumber, this.instanceId, this.inputFields)
    //   }
      // if (this.instanceId > 1) {
      //   this.lists = this.modelStore.getStageLists(stageNumber, 1)
        
      // }
    }
  },

  mounted() {
    this.navigationStore.step(stageNumber)
    this.navigationStore.items[stageNumber].active = true
  },

  methods: {
    nextStep() {
      this.navigationStore.items[stageNumber].valid = true
      this.navigationStore.step(5)
      this.navigationStore.navigate(5)
    },

    IdPlusEins() {
      var currStage = this.modelStore.getStage(stageNumber)
      if (currStage) {
        this.instanceId = parseInt(currStage.currentInstance)
      }
      this.modelStore.setNextInstance(stageNumber)
      this.instanceId = currStage.currentInstance
      this.inputFields.forEach(field => {
        field.value = ""
      })
      this.modelStore.fillStageParams(stageNumber, this.instanceId, this.inputFields)
      this.lists = this.modelStore.getStageLists(stageNumber, 1)
    },

    deleteItem(option) {
      if (this.modelStore.setListToDelete(stageNumber, option.id)) {
        this.lists = this.modelStore.getStageLists(stageNumber, 1)
        this.instanceId = this.modelStore.getCurrentInstance(stageNumber)
        this.ShowListParams(this.instanceId)
      }
    },

    addItem(option) {
      if (this.modelStore.cancelListToDelete(stageNumber, option.id)) {
        this.lists = this.modelStore.getStageLists(stageNumber, 1)
        this.instanceId = this.modelStore.getCurrentInstance(stageNumber)
        this.ShowListParams(this.instanceId)
      }
    },

    ShowListParams(option) {
      this.instanceId = parseInt(option)
      if (option.id) {
        this.instanceId = parseInt(option.id)
      } else {
        this.instanceId = option
      }
      var currStage = this.modelStore.getStage(stageNumber)
      if (currStage)
        currStage.currentInstance = this.instanceId

      var tempFile = {
        fileName: "",
        id: "",
        originalName: "",
        url: "",
      }

      var newList = []
      this.inputFields.forEach(prop => {
        newList.push({

          id: prop.id,
          title: prop.title,
          type: prop.type,
          value: "",
          isRequired: prop.isRequired
        })
        var dataProp = this.modelStore.getParamValue(stageNumber, this.instanceId, prop.id);
        if (prop.id === 4) {
          tempFile.fileName = prop.title
          tempFile.url = prop.value
          tempFile.id = prop.id
          tempFile.originalName = prop.title
        }

        if (dataProp)
          newList[newList.length - 1].value = dataProp

      });
      this.inputFields = newList;
      this.file = tempFile
    },

    // post and get then next step
    async postDataNextStep() {
      var model = this.modelStore.model;
      await httpPost(ApiNames.model_save, model);
      this.getData()
    },
    async getData() {

      var response = await httpGet(ApiNames.model_get, this.modelStore.model.id, this.modelStore.token)
      var data = response.data;
      this.modelStore.model = data;

      this.navigationStore.items[stageNumber].valid = true
      this.navigationStore.step(5)
      this.navigationStore.navigate(5)
    },

    // post and get then redirect preview
    async postDataPreview() {
      var model = this.modelStore.model;
      await httpPost(ApiNames.model_save, model);
      this.getDataPreview()
    },

    async getDataPreview() {//async
      var response = await httpGet(ApiNames.model_get, this.modelStore.model.id, this.modelStore.token)
      var data = response.data;
      this.modelStore.model = data;
      this.modelStore.showPreview();

      this.lists = this.modelStore.getStageLists(stageNumber, 1)
      var currStage = this.modelStore.getStage(stageNumber)
      this.instanceId = currStage.currentInstance
      this.ShowListParams(currStage.currentInstance)
      this.instanceId = currStage.currentInstance
    },
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

form {
  width: 200%;
}

.base-card {
  width: 40vw;
  height: fit-content;
}

.company-article-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  height: 85%;
  margin: auto;
}

.input-container {
  position: relative;
  display: flex;
  width: 70.5%;
  flex-direction: column;
}

#preview {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  margin-bottom: 1rem;
}

.cmp-list {
  margin-bottom: 1rem;
  list-style-type: none;
  max-height: 5rem;
  overflow-y: auto;
  border-radius: 4px;
  padding: 4px 0px;
  padding-left: 4px;
  box-shadow: var(--list-shadow);
}

.to_delete {
  text-decoration: line-through;
}

li {
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  margin-bottom: 2px;

}

li span {
  cursor: pointer;
}

li input:hover {
  transition: 0.2s;
  box-shadow: 2px 2px 5px var(--dark-gray);
  background-color: var(--mdblue-hover);
  border-color: var(--mdblue-hover);
}

li span:hover {
  color: var(--mdblue-hover);
}

.upload-container {
  margin-bottom: 1rem;
  height: fit-content;
}

.li-container {
  margin-bottom: .5rem;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 1rem;
}

.editor-container {
  /* height: 200px; */
  margin-top: .5rem;
}
</style>