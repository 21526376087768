<template>
    <header id="Header">

        <div class="img-container">
            <!-- <img v-on:click="goHome()" id="logo" class="logoClass"
                src="https://www.mdesign.de/site/assets/files/1/logo-mdesign_ukraine.svg" alt="MDESIGN" />
                 -->
                <img v-on:click="goHome()" id="logo" class="logoClass"
                :src="this.imgMDESIGN" alt="MDESIGN" />



            <ButtonMobile v-show="toggleMenu === 'mobile'" @click-button="methodMenuMobile" :value="valueMenuMobile" />

        </div>

    </header>
    <div class="navBar-container">
        <Navigation class="navBar " id="navBar" />
    </div>
</template> 

<script>
import ButtonMobile from './ButtonMobile/ButtonMobile.vue';
import MDESIGN from '../../../src/assets/MDESIGN.svg'
import Navigation from '../Navigation/Navigation.vue'
import { useNavigationStore } from '../../stores/navigation.js'

export default {
    name: "Header",
    components: {
        ButtonMobile,
        Navigation
    },

    created() {
        window.addEventListener('scroll', this.scrollFunction);
    },
    mounted() {

        this.showButtonMobile(this.mediaQuerieList);
        this.mediaQuerieList.addEventListener('change', this.showButtonMobile);
    },
    unmounted() {
        window.removeEventListener('scroll', this.scrollFunction);
    },
    data() {
        return {
            mediaQuerieList: matchMedia("(max-width: 822px)"),
            toggleMenu: "desktop",
            navigationStore: useNavigationStore(),
            imgMDESIGN: MDESIGN
        }
    },
    methods: {

        goHome() {

            this.navigationStore.navigate(-1)
        },
        showButtonMobile(mediaQuerieList) {
            if (mediaQuerieList.matches) {
                this.toggleMenu = "mobile";
            } else {
                this.toggleMenu = "desktop";
            }
        },
        scrollFunction() {

            if (document.documentElement.scrollTop > 30) {
                document.getElementById("Header").classList.add("top-nav-collapse");
                document.getElementById("logo").classList.add("small-logo");
            } else if (document.documentElement.scrollTop < 30) {
                document.getElementById("Header").classList.remove("top-nav-collapse");
                document.getElementById("logo").classList.remove("small-logo");
            }
        }
    },
    props: {
        valueMenuMobile: {
            require: true,
            type: Boolean
        },
        methodMenuMobile: {
            require: true,
            type: Function
        }
    }
}
</script>
<style scoped>
header#Header {
    width: 100%;
    /* background-color: var(--background-blue); */
    display: grid;
    grid-template-areas:
        "logoArea login"
        "navBar"
    ;
}

.img-container {
    box-shadow: 2px 2px 15px var(--light-gray);
    width: 100%;
    background-color: #fff;
}

.navBar-container {
    background-color: var(--background-blue);
    justify-content: center;
    height: 100%;
    margin-top: 1rem;
    border-bottom: 1px solid var(--light-gray);
    width: 100%;
    padding-bottom: 1rem;
}

.header-content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.split-container {
    width: 100%;
    height: 1rem;
    opacity: 0;
}

img#logo {
    grid-area: logoArea;
    width: 12rem;
    margin-left: 7.5vw;
    margin-right: 5rem;
    vertical-align: baseline;
    cursor: pointer;
    margin-top: 1rem;
}

.small-logo {
    width: 10rem !important;
}

h1#headerTxt {
    color: var(--black);
    margin-left: 7.5vw;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 3.5rem;
}

header#Header,
nav#navBar,
ul#listItems {
    display: flex;
    align-items: center;
}

.top-nav-collapse-txt {

    font-size: 2.5rem !important;
}

.top-nav-collapse {
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    position: fixed;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    top: 0;
    width: 100%;
    z-index: 999;
    height: 4rem !important;
}

nav#navBar {
    margin-right: 6.5vw;
    transition: all 0.2s;
    grid-area: navBar;
}

ul#listItems {
    width: 20rem;

    justify-content: space-around;
}

@media screen and (max-width: 443px) {
    header#Header {
        height: 3.5rem;
        margin: 5vw;
    }

    .top-nav-collapse {

        margin: 0px !important;
        height: 4.3rem !important;
    }

    h1#headerTxt {
        font-size: 2rem;
        margin-top: 0.5rem;
    }

    .top-nav-collapse-txt {
        font-size: 2rem !important;
    }

    img#logo {
        width: 7.5rem;
    }

    .navBar {

        display: none !important;

    }
}
</style>
 
