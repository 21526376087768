<template>
  <base-card v-if="!fatalError" class="base-card" :hasPreview="true" :logo="logoCompany" @post="this.postDataPreview()"
    :hasTitle="true" title="Allgemeine Unternehmensdaten">
    <div class="general-data-container">
      <div class="form-container">
        <form action="">
          <div class="form-top">
            <input-field :key="inputFields[0].id" :id="inputFields[0].id" :title="inputFields[0].title"
              :type="inputFields[0].type" :isRequired="inputFields[0].isRequired" :value="inputFields[0].value"
              :stageId="1" :listId="this.instanceId"></input-field>
          </div>
          <div class="div-horizontal">
            <input-field :key="inputFields[1].id" :id="inputFields[1].id" :title="inputFields[1].title"
              :type="inputFields[1].type" :isRequired="inputFields[1].isRequired" :value="inputFields[1].value"
              :stageId="1" :listId="this.instanceId"></input-field>
            <input-field :key="inputFields[2].id" :id="inputFields[2].id" :title="inputFields[2].title"
              :type="inputFields[2].type" :isRequired="inputFields[2].isRequired" :value="inputFields[2].value"
              :stageId="1" :listId="this.instanceId"></input-field>
          </div>
          <div class="div-horizontal">
            <input-field :key="inputFields[4].id" :id="inputFields[4].id" :title="inputFields[4].title"
              :type="inputFields[4].type" :isRequired="inputFields[4].isRequired" :stageId="1"
              :value="inputFields[4].value" :listId="this.instanceId"></input-field>
            <input-field :key="inputFields[3].id" :id="inputFields[3].id" :title="inputFields[3].title"
              :type="inputFields[3].type" :isRequired="inputFields[3].isRequired" :stageId="1"
              :value="inputFields[3].value" :listId="this.instanceId"></input-field>
          </div>
          <input-field :key="inputFields[5].id" :id="inputFields[5].id" :title="inputFields[5].title"
            :type="inputFields[5].type" :isRequired="inputFields[5].isRequired" :stageId="1" :value="inputFields[5].value"
            :listId="this.instanceId"></input-field>
          <input-field :key="inputFields[6].id" :id="inputFields[6].id" :title="inputFields[6].title"
            :type="inputFields[6].type" :isRequired="inputFields[6].isRequired" :stageId="1"
            hint="(Format: +49 234 30703-0)" :value="inputFields[6].value" :listId="this.instanceId"></input-field>
          <input-field :key="inputFields[7].id" :id="inputFields[7].id" :title="inputFields[7].title"
            :type="inputFields[7].type" :isRequired="inputFields[7].isRequired" hint="(Format +49 234 30703-99)"
            :stageId="1" :value="inputFields[7].value" :listId="this.instanceId"></input-field>
          <input-field :key="inputFields[8].id" :id="inputFields[8].id" :title="inputFields[8].title"
            :type="inputFields[8].type" :isRequired="inputFields[8].isRequired" hint="(info@... / vertrieb@...)"
            :stageId="1" :value="inputFields[8].value" :listId="this.instanceId"></input-field>
          <input-field :key="inputFields[9].id" :id="inputFields[9].id" :title="inputFields[9].title"
            :type="inputFields[9].type" :isRequired="inputFields[9].isRequired" :stageId="1" :value="inputFields[9].value"
            :listId="this.instanceId" hint="(Format http://www... / https://www...)"></input-field>
        </form>
        <!-- <Upload :ueberschrift="'Upload Firmenlogo:'" :listId="this.instanceId" :stageId="1" :value="inputFields[10].value" /> -->
        <Upload :ueberschrift="'Upload Firmenlogo:'" :stageId="1" :listId="this.instanceId" :id="11" :file="this.file"
          :key="inputFields[10].id" />
      </div>
      <div class="button-container">
        <one-button title="Weiter" type="submit" v-on:click.prevent="postDataNextStep()"></one-button>
      </div>
    </div>
  </base-card>
  <h1 v-if="fatalError">Keine Daten gefunden!</h1>
</template>

<script>
import OneButton from '../components/BasicComponents/OneButton.vue'
import InputField from '../components/BasicComponents/InputField.vue';
import BaseCard from '../components/BasicComponents/BaseCard.vue'
import { useNavigationStore } from '../stores/navigation'
import imageFabrik from '../assets/desktop/fabrik.svg'
import { useModelStore } from '../stores/model'
import { ApiNames, httpGet, httpPost } from '../libs/httpHelper'
import Upload from "../components/FileUpload/Upload.vue";

const stageNumber = 1;

export default
  {
    components: { OneButton, InputField, BaseCard, Upload },
    data() {
      return {
        logoCompany: imageFabrik,
        generalData: ["", ""],
        name: "GeneralData",
        navigationStore: useNavigationStore(),
        modelStore: useModelStore(),
        fatalError: false,
        instanceId: -1,
        file: {
          fileName: "",
          id: "",
          originalName: "",
          url: "",
        },
        inputFields: [
          {
            id: 1,
            title: "Firmenname",
            type: "text",
            value: "",
            isRequired: true
          }, {
            id: 2,
            title: "Straße",
            type: "text",
            value: "",
            isRequired: true
          }, {
            id: 3,
            title: "Hausnummer",
            value: "",
            type: "text",
            isRequired: true
          }, {
            id: 4,
            title: "Ort",
            type: "text",
            value: "",
            isRequired: true
          }, {
            id: 5,
            title: "PLZ",
            type: "text",
            value: "",
            isRequired: true
          }, {
            id: 6,
            title: "Land",
            type: "text",
            value: "",
            isRequired: true
          }, {
            id: 7,
            title: "Telefonnummer",
            type: "text",
            value: "",
            isRequired: true
          }, {
            id: 8,
            title: "Faxnummer",
            type: "text",
            value: "",
            isRequired: false
          }, {
            id: 9,
            title: "Allgemeine E-Mail-Adresse",
            type: "email",
            value: "",
            isRequired: true
          }, {
            id: 10,
            title: "Homepage",
            type: "url",
            value: "",
            isRequired: true
          }, {
            id: 11,
            title: "UploadImage",
            type: "text",
            value: ""
          }
        ],
      };
    },

    beforeMount() {
      if (!this.modelStore.model) {
        this.fatalError = true
      }
      if (!this.modelStore.checkValidModel())
        this.fatalError = true

      var currStage = this.modelStore.getStage(stageNumber)
      if (currStage) {
        this.instanceId = currStage.currentInstance

        var uploadParam = this.modelStore.getListParams(stageNumber, this.instanceId).params[10]

        this.file.fileName = uploadParam.title
        this.file.url = uploadParam.value
        this.file.id = uploadParam.id
        this.file.originalName=uploadParam.title
      }
    },

    mounted() {
      this.navigationStore.step(1)
      this.navigationStore.items[1].active = true
    },

    methods:
    {
      // post and get then next step
      async postDataNextStep(){
        var model = this.modelStore.model;
        await httpPost(ApiNames.model_save, model);
        this.getData()
      },
      async getData(){

        var response = await httpGet(ApiNames.model_get, this.modelStore.model.id, this.modelStore.token)
        var data = response.data;
        this.modelStore.model = data;

        this.navigationStore.items[1].valid = true
        this.navigationStore.step(2)
        this.navigationStore.navigate(2)
      },
      // post and get then redirect preview
      async postDataPreview() {
        var model = this.modelStore.model;
        await httpPost(ApiNames.model_save, model);
        this.getDataPreview()
      },

      async getDataPreview() {
        var response = await httpGet(ApiNames.model_get, this.modelStore.model.id, this.modelStore.token)
        var data = response.data;
        this.modelStore.model = data;
        this.modelStore.showPreview();
      }
    },
  };
</script>

<style scoped>
.base-card {
  width: 40vw;
  height: 65vh;
}

.card-body {
  height: fit-content;
}

.general-data-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  height: 100%;
}

.form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 75%;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  margin-bottom: 1rem;
}

#btn-weiter {
  position: relative;
  left: 0px;
}

.div-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-container.input-Straße {
  width: 66%;
  margin: 0;
}

.input-container.input-Hausnummer {
  width: 33%;
  margin: 0;
}

.input-container.input-Ort {
  width: 66%;
}

.input-container.input-PLZ {
  width: 33%;
}

.input-container.input-Land {
  margin: 0;
}


.input-container label.active {
  position: absolute;
  top: -10px;
  font-size: 0.75rem;
}

.country-select {
  width: 35%;
}
</style> 