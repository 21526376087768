import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'

import router from './router'
import VueTippy from 'vue-tippy'

import Quill from 'quill'
// import PlainClipboard from './libs/PlainClipboard'
 import PasteSmart from './libs/PasteSmart'



// import VueCountryDropdown from "vue-country-dropdown";
// Quill.register('modules/clipboard', PlainClipboard, true)
 Quill.register('modules/clipboard', PasteSmart, true)
const app = createApp(App)
const pinia = createPinia()

pinia.use((context) => {
  const storeId = context.store.$id
  //console.log(storeId)
  const serializer = {
    serialize: JSON.stringify,
    deserialize: JSON.parse
  }
  const fromStorage = serializer.deserialize(window.localStorage.getItem(storeId))
  //console.log(fromStorage)
  if (fromStorage)
    context.store.$patch(fromStorage)

  context.store.$subscribe((mutation, state) => {
    var fileData = serializer.serialize(state)
    try{
  
      window.localStorage.setItem(storeId, fileData)
    }catch(e){   console.log( "Error",fileData)}
  })
})

app.use(pinia)

// app.use(VueCountryDropdown);
app.use(router)
app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
  }
)
app.mount('#app')