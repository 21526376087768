
import { defineStore } from 'pinia'
// import { v4 as uuidv4 } from 'uuid'
import { ref, watch } from 'vue'
import { useNavigationStore } from './navigation'
import { ApiNames, httpGet, httpPost, httpPostAuth, httpGetByUrl, customConfig } from '../libs/httpHelper'//httpPost
import { encode, decode } from 'js-base64';

export const useModelStore = defineStore('model', () => {
  const navigationStore = useNavigationStore()

  // state members
  const model = ref({})
  const loading = ref(false)
  let saveTimer = undefined
  const initialized = ref(false)
  const saveInterval = 1000
  const ProgressStates = { zero: -1, welcome: 0, general: 1, locations: 2, socialmedia: 3, articles: 4, products: 5, finish: 6 }

  watch(
    [() => model.value.progress, () => model.value.stages],

    () => {
      // most changes to the model are running through this function
      //console.log('model changed', model.value)
      if (saveTimer != undefined) {
        clearTimeout(saveTimer)
        saveTimer = undefined
      }
      saveTimer = setTimeout(saveModel, saveInterval)

      //updateModel(); too much

    },
    { deep: true }
  )

  function updateParamValue(stageId, listId, propId, value) {
    var stage = getStage(stageId)
    if (stage) {
      var paramList = stage.list.find(list => list.id === listId)
      if (!paramList)
        paramList = stage.list.find(list => list.id === listId - 1)
      if (paramList) {
        var proptoUpdate = paramList.params.find(p => p.id === propId)
        if (proptoUpdate) {
          proptoUpdate.value = value;
        }
      }
    }
  }

  function updateParamTitle(stageId, listId, propId, value) {
    var stage = getStage(stageId)
    if (stage) {
      var paramList = stage.list.find(list => list.id === listId)
      if (!paramList)
        paramList = stage.list.find(list => list.id === listId - 1)
      if (paramList) {
        var proptoUpdate = paramList.params.find(p => p.id === propId)
        if (proptoUpdate) {
          proptoUpdate.title = value;
        }
      }
    }
  }

  function updateImageValue(stageId, listId, propId, value, name) {
    var stage = getStage(stageId)
    if (stage) {
      var paramList = stage.list.find(list => list.id === listId)
      if (!paramList)
        paramList = stage.list.find(list => list.id === listId - 1)
      if (paramList) {
        var proptoUpdate = paramList.params.find(p => p.id === propId)
        if (proptoUpdate) {
          proptoUpdate.value = value;
          proptoUpdate.title = name;
        }
      }
    }
  }

  function setNextInstance(stageNumber) {
    var stage = getStage(stageNumber)
    stage.currentInstance = 1
    if (stage) {
      stage.list.forEach(li => {
        if (li.id >= stage.currentInstance)
          stage.currentInstance = li.id + 1
      })
    }
  }

  function getCurrentInstance(stageNumber) {
    var stage = getStage(stageNumber)
    stage.currentInstance = 1
    if (stage) {
      stage.list.forEach(li => {
        if (li.id >= stage.currentInstance)
          stage.currentInstance = li.id
      })
    }
    return stage.currentInstance
  }

  function newModel(uid) {
    //console.log("new model")
    loading.value = false
    //console.log("new model -> " + uid)
    // TODO - this id should be generated by the server, not the frontend
    model.value = newModelInstance(uid)
  }

  function fillStageParams(stageNumber, currentId, inputFields) {
    var currentStage = getStage(stageNumber);
    if (currentStage) {
      var currentInstance = null;
      if (!currentStage.list) {
        currentStage.list = [];
        currentStage.list.push({ "id": currentId, "toDelete": false, "params": [], "sorting": currentId });
        currentInstance = currentStage.list.find(moreL => moreL.id === currentId);
      } else {
        currentInstance = currentStage.list.find(moreL => moreL.id === currentId);
        if (!currentInstance) {
          currentStage.list.push({ "id": currentId, "toDelete": false, "params": [], "sorting": currentId });
          currentInstance = currentStage.list.find(moreL => moreL.id === currentId);
          currentInstance.sorting = currentId
        }
      }
      if (inputFields)
        inputFields.forEach(input => {
          var currentParam = currentInstance.params.find(param => param.id === input.id);
          if (!currentParam)
            currentInstance.params.push({ id: input.id, type: input.type, title: input.title, value: input.value, valid: false })
        }
        );
    }
  }

  function deleteListItem(stageNumber, listId) {
    var currentStage = getStage(stageNumber)
    if (currentStage) {
      let array1 = currentStage.list;
      var hasId = (element) => element.id === listId;
      var index = array1.findIndex(hasId)
      currentStage.list.splice(index, 1)
    }
  }

  function newModelInstance(uid) {
    //console.log("new model instance")
    loading.value = false
    // ersetzen durch api-call mit gecrypteter uid
    // TODO - this id should be generated by the server, not the frontend
    return {
      id: uid,
      version: Date.now(),
      name: new Date().toLocaleDateString('de-DE'),
      valid:false,
      token:"",
      progress: ProgressStates.zero,
      stages: [],
    }
  }

  function setToken(token){
    if(model.value){
      model.value.token = token;
    }
  }

  function checkValidModel() {
    if (this.model.id === "undefined" || !this.model.id)
      return false
    return true
  }

  function getStage(number) {
    if (!model.value)
      newModel()
    if (!model.value.stages) {
      model.value.stages = []
      createStage(number)
    }
    var currentStage = model.value.stages.find(stage => stage.id === number);
    return currentStage;
  }

  function createStage(number) {
    var currentStage = getStage(number)
    if (!currentStage)
      model.value.stages.push({ id: number, valid: false, complete: false, currentInstance: 1, list: [] })
    currentStage = getStage(number)
    return currentStage
  }

  async function saveModel() {
    clearTimeout(saveTimer)
    saveTimer = undefined

    model.value.version = Date.now()
    const dataToSend = { model: model.value }
    //TODO - fetch data to server
    //console.log('save model', dataToSend)
  }

  async function loadModel(modelId) {
    //console.log('load model', modelId)
    loading.value = true
    //TODO - get model from server
    const response = { status: 200, data: newModelInstance() }

    if (response?.status === 200 && response?.data) {
      model.value = response.data.model
      initialized.value = true
      navigationStore.navigate(model.value.progress);

    } else {
      model.value = {}
      initialized.value = false
    }
    loading.value = false
  }

  async function deleteModel() {
    const dataToSend = { id: model.value.id }
    //console.log('delete model', dataToSend)
  }

  function getListParams(stageNumber, listId) {
    var currStage = getStage(stageNumber)
    if (currStage) {
      var listParams = currStage.list.find(list => list.id == listId)
      return listParams;
    }
    return;
  }

  function getStageLists(stageNumber, startList) {
    var lists = []
    var currStage = getStage(stageNumber)
    if (currStage) {
      for (var i = startList - 1; i < currStage.list.length; i++) {
        lists.push(currStage.list[i])
      }
    }
    return lists;
  }

  function getStageLastList(stageNumber) {
    var list = []
    var currStage = getStage(stageNumber)
    if (currStage)
      list = currStage.list[currStage.list.length - 1]

    return list
  }

  function updateStageValidation(stageNumber) {
    var currStage = getStage(stageNumber)
    var stageErrors = [{
      listId: null,
      propId: null,

    }]
    if (currStage) {
      currStage.list.forEach(list => {
        list.forEach(param => {
          if (param.valid !== true) {
            stageErrors.push({
              listId: list.id,
              propId: param.id

            })
          }
        })
      })
    }
    return stageErrors
  }

  async function updateModel() {
    const dataToSend = { model: model.value }
    //console.log('update model', dataToSend)
  }

  function setProgress(progress) {
    model.value.progress = progress
    createStage(progress)
  }

  function getParams(stageNumber) {
    var currentStage = getStage(stageNumber)
    return currentStage.list;
  }

  function getParamValue(stageNumber, listId, propId) {
    var stage = getStage(stageNumber)
    var val = undefined
    if (stage) {
      var currentInstance = stage.list.find(ml => ml.id === listId)
      if (currentInstance) {
        var prop = currentInstance.params.find(p => p.id === propId)
        if (prop)
          val = prop
      }
    }
    if (val === undefined)
      return val;
    return val.value;
  }

  function getParamTitle(stageNumber, listId, propId) {
    var stage = getStage(stageNumber)
    var val = undefined
    if (stage) {
      var currentInstance = stage.list.find(ml => ml.id === listId)
      if (currentInstance) {
        var prop = currentInstance.params.find(p => p.id === propId)
        if (prop)
          val = prop
      }
    }
    if (val === undefined)
      return val;
    return val.title;
  }
  function setListToDelete(stageNumber, listId) {
    var currStage = getStage(stageNumber)
    if (!currStage) {
      return false
    }
    var listToDelete = currStage.list.find(list => list.id === listId)
    if (!listToDelete) {
      return false
    }
    listToDelete.toDelete = true;
    return true
  }

  function cancelListToDelete(stageNumber, listId) {
    var currStage = getStage(stageNumber)
    if (!currStage) {
      return false
    }
    var listToDelete = currStage.list.find(list => list.id === listId)
    if (!listToDelete) {
      return false
    }
    listToDelete.toDelete = false;
    return true
  }

  async function sendEmail(firma) {

    var email = "mailsender@tedata.de"//document.getElementById("mail").value.trim()_vorname, _nachname_, _email
    var password = "T9uECy$a4CJz&Wv4"//document.getElementById("pw").value.trim()

    var emailSent = ""
    var userMail = getParamValue(6, 1, 4)
    //var topass = email + " " + password

    //topass = btoa(topass)
    var obj = {
      Email: email,
      Password: password
    }

    var base64 = btoa(JSON.stringify(obj))


    var response = await httpPost(ApiNames.authorization, base64, customConfig)

    if (response?.status === 200 && response?.data) {


      const vorlage = await httpGetByUrl(
        window.location.origin + "/emailtemplate.html"
      );

      if (vorlage?.status === 200 && vorlage?.data) {

        var idRes = await httpGet(ApiNames.decr_id_get, model.value.id, "application/json")

        //console.log(idRes)

       // var _url = "https://82.165.252.3/index.php?id=500&tx_adediting_pi%5bhersteller%5d=" + idRes.data
        var _url = "https://www.mdesign.info/index.php?id=500&tx_adediting_pi%5bhersteller%5d=" + idRes.data

        var bodyText = vorlage.data.replace("$Firmenname$", firma)
        bodyText = bodyText.replace("$nummer$", idRes.data)
        bodyText = bodyText.replace("$link$", _url)
        bodyText = bodyText.replace("$vorname,name$", getParamValue(6, 1, 2) + " " + getParamValue(6, 1, 3))
        bodyText = bodyText.replace("$email$", userMail)


        var mailJson = {

          receiver: "marketing-services@mdesign.de",// + "marketing-services@mdesign.de",//document.getElementById("receiver").value.trim(),
          cc: "",//document.getElementById("cc").value.trim(), 
          sender: "service@mdesign.de",//document.getElementById("sender").value.trim(),
          senderName: "Datatool Manager Marketing",// document.getElementById("senderName").value.trim(),
          body: bodyText,//document.getElementById("body").value,
          subject: "Freigabe zu MDESIGN Firmen- und Produktprofil",//document.getElementById("subject").value.trim(),
          attachments: [],
          blindCC: "", //document.getElementById("blindCC").value.trim(),
          html: true,// document.getElementById("html").checked,
          mailer: "service"//document.getElementById("mailer").value.trim(),
        }
        var mail64 = encode(JSON.stringify(mailJson))

        var sendResp = await httpPostAuth(ApiNames.sendMail, mail64, response.data)
        if (sendResp?.status == 200 && sendResp?.data) {
          emailSent = sendResp.data
        } else {
          emailSent = "err"
        }

        if (userMail) {
          mailJson.receiver = userMail
          mail64 = encode(JSON.stringify(mailJson))
          var sendResp1 = await httpPostAuth(ApiNames.sendMail, mail64, response.data)
          if (sendResp1?.status == 200 && sendResp1?.data) {
            emailSent = sendResp1.data
          } else {
            emailSent = "err"
          }
        }



      }
    } else { emailSent = "err" }
    return emailSent
  }

  async function showPreview() {
    const url = "https://www.mdesign.info/index.php?id=500&tx_adediting_pi%5bhersteller%5d="
   // const url = "https://82.165.252.3/index.php?id=500&tx_adediting_pi%5bhersteller%5d="        // prodServer
    //const url = "https://82.165.162.79/index.php?id=500&tx_adediting_pi%5bhersteller%5d="     // prodClone

    const target = "_blank"
    var response = await httpGet(ApiNames.decr_id_get, model.value.id, "application/json")
    window.open(url + response.data, target).focus()
  }


  function checkURL(str) {
    if(!str)
      return
    if((str.startsWith('http://') || str.startsWith('https://'))){
      return true
    }
    return false
  }


  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  return {
    model,
    getStage,
    getParams,
    getParamValue,
    getListParams,
    createStage,
    fillStageParams,
    updateParamValue,
    newModel,
    newModelInstance,
    saveModel,
    loadModel,
    deleteModel,
    updateModel,
    setProgress,
    getStageLists,
    updateStageValidation,
    setNextInstance,
    updateImageValue,
    getParamTitle,
    updateParamTitle,
    deleteListItem,
    getCurrentInstance,
    getStageLastList,
    setListToDelete,
    showPreview,
    cancelListToDelete,
    //checkInputs,
    checkURL,
    checkValidModel,
    sendEmail,
    validateEmail,
    setToken
  }
})