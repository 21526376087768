<template>
  <div class="flex justify-center">
    <div class="flex flex-row flex-start" v-for="(item, index) in navigationStore.items" :key="item.name">
      <NavigationItem :text="item.name" :active="item.active" :valid="item.valid" :available="item.available"
        :icon="item.icon" @navigateTo="navigateTo(index)" />
    </div>
    <div class="statur-bar" ></div> <!--:style="Width"-->
  </div>
</template>

<script>
import NavigationItem from './NavigationItem.vue'
import { useNavigationStore } from '../../stores/navigation'

export default {
  name: "Navigation",
  components: {
    NavigationItem,
  },
  data() {
    return {
      navigationStore: useNavigationStore()
    }
  },
  mounted() {

  },
  methods: {
    navigateTo(index) {
      if(this.navigationStore.items[index].available == true)
        this.navigationStore.navigate(index);
    }
  }

}
</script>
<style scoped>
.nodisplay {

  display: none;

}

/* .statur-bar {
  position: absolute;
  z-index: -1;
  width: 40%;
  top: 7.2rem;
  top: 50%;
  height: 2px;
  background-color: var(--mdblue) !important;
} */
</style>