<template>
  <div class="single-button-container">
    <button :type="this.type">{{ this.title }}</button>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    type: String,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
* {
  --var-lightblue: #009ee3;
}

button:hover {
  transition: 0.2s;
  box-shadow: 2px 2px 5px var(--dark-gray);
  background-color: var(--mdblue-hover);
  border-color: var(--mdblue-hover);
}

button {
  width: auto;
  height: 50px;
  border: solid 1px var(--mdblue);
  background: var(--mdblue);
  color: #fff;
  font-family: Roboto-Regular;
  border-radius: 3px;
  font-size: 1rem;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  padding: 8px 16px;
  padding: .5rem 1rem;
  cursor: pointer;
  will-change: auto;
  box-shadow: 3px 3px 10px var(--light-gray);
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out;
}

button:active { background-color: SteelBlue; color: White; }
</style>