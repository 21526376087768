<template>
  <div v-if="!fatalError" class="social-media-container">
    <base-card class="base-card" :logo="imageSocialMedia" title="Social Media" :hasTitle="true">
      <div class="content-left">
        <div class="common-links">
          <input-field v-for="inputField in inputFieldsCommon" :id="inputField.id" :key="inputField.id"
            :title="inputField.title" :type="inputField.type" :isRequired="inputField.isRequired" :stageId="3"
            :value="inputField.value" :listId="0"></input-field>
        </div>
        <div class="btn-left">
        </div>
      </div>
    </base-card>
    <base-card :logo="imageOwnLinks" :hasPreview="true" @post="postDataPreview()" title="Interessante Links"
      class="base-card" :hasTitle="true">
      <div class="content-right">
        <div class="li-container" v-if="this.lists.length > 0 && this.lists[0].params[0].value !== ''">
          <ul class="cmp-list">
            <li v-for="option in this.lists" :title="option.params[0].value" :id="option.id" :key="'A' + option.id">
              <span :class="{ to_delete: option.toDelete }" @click="ShowListParamsOwn(option)">{{ option.params[0].value
              }} </span>
              <div>
                <ListButton :imageString="pencilSvg" :hasImg="true" @click="ShowListParamsOwn(option)"></ListButton>
                <ListButton v-show="!option.toDelete" @click="deleteItem(option)" class="del-btn" type="button" title="x"
                  :key="'B' + option.id" />
                <ListButton v-show="option.toDelete" @click="addItem(option)" class="del-btn" type="button" title="+"
                  :key="'C' + option.id" />
              </div>
            </li>
          </ul>
        </div>
        <div class="own-links">
          <InputField :id="7" :title="'Linkname'" :type="'text'" :isRequired="false" :stageId="3"
            :listId="this.instanceId" :value="this.inputFieldsOwn[0].value" :key="this.inputs_reload" />
          <InputField :id="8" :title="'Link'" :type="'url'" :isRequired="false" :stageId="3" :listId="this.instanceId"
            :value="this.inputFieldsOwn[1].value" :key="this.inputs_reload" />
          <p>Verlinkung auf:</p>
          <custom-drop-down-vue :id="9" :stageId="3" :listId="this.instanceId" :value="this.inputFieldsOwn[2].value"
            class="drop-down" capture="Linktyp" :options="selectOptions"
            :selectedOption="getSelected()"></custom-drop-down-vue>
        </div>
        <div class="btn-right">
          <one-button title="Weiteren Link hinzufügen" @click.prevent="IdPlusEins()"></one-button>
          <one-button title="Weiter/Überspringen" v-on:click.prevent="postDataNextStep()"></one-button>
        </div>
      </div>
    </base-card>
  </div>
  <h1 v-if="fatalError">Keine Daten gefunden!</h1>
</template>

<script>
import InputField from "../components/BasicComponents/InputField.vue";
import OneButton from "../components/BasicComponents/OneButton.vue";
import BaseCard from '../components/BasicComponents/BaseCard.vue'
import { useNavigationStore } from '../stores/navigation'
import CustomDropDownVue from "../components/BasicComponents/CustomDropDown.vue";
import imageSocialMedia from '../assets/desktop/social-media-marketing.svg'
import imageOwnLinks from '../assets/desktop/netz.svg'
import { useModelStore } from '../stores/model'
import ListButton from '../components/BasicComponents/ListButton.vue'
import { ApiNames, httpGet, httpPost } from '../libs/httpHelper'
import PencilSvg from '../assets/desktop/pencil.svg'

const stageNumber = 3;

export default {
  components: { InputField, OneButton, BaseCard, CustomDropDownVue, ListButton },
  data() {
    return {
      imageSocialMedia: imageSocialMedia,
      imageOwnLinks: imageOwnLinks,
      navigationStore: useNavigationStore(),
      modelStore: useModelStore(),
      instanceId: 1,
      inputs_reload: 0,
      pencilSvg: PencilSvg,
      fatalError: false,
      options: [],
      lists: [
        {
          id: -1,
          sorting: -1,
          params: [{
            id: -1,
            type: "",
            title: "",
            value: "",
            valid: false
          }]
        }
      ],
      listOwnLinks: [],
      inputFieldsCommon: [
        {
          id: 1,
          title: "Twitter",
          type: "url",
          value: "",
          isRequired: false
        }, {
          id: 2,
          title: "Youtube",
          type: "url",
          value: "",
          isRequired: false
        }, {
          id: 3,
          title: "Facebook",
          type: "url",
          value: "",
          isRequired: false
        }, {
          id: 4,
          title: "LinkedIn",
          type: "url",
          value: "",
          isRequired: false
        }, {
          id: 5,
          title: "Xing",
          type: "url",
          value: "",
          isRequired: false
        }, {
          id: 6,
          title: "Instagram",
          type: "url",
          value: "",
          isRequired: false
        }
      ],
      inputFieldsOwn: [
        {
          id: 7,
          title: "Linkname",
          type: "text",
          value: "",
          isRequired: false
        }, {
          id: 8,
          title: "Link",
          type: "url",
          value: "",
          isRequired: false
        }, {
          id: 9,
          title: "Link Kategorie",
          type: "text",
          value: "",
          isRequired: false
        }
      ],
      selectOptions: [
        {
          id: 0,
          title: "Sonstiges",
        }, {
          id: 1,
          title: "Dokument",
        }, {
          id: 2,
          title: "3D/CAD-Daten",
        }, {
          id: 3,
          title: "Shop",
        }, {
          id: 4,
          title: "Onlinetool",
        }
      ],
      itemToDelete: false
    };
  },

  beforeMount() {
    if (!this.modelStore.model) {
      this.fatalError = true
    }
    if (!this.modelStore.checkValidModel())
      this.fatalError = true

    var currStage = this.modelStore.getStage(stageNumber)

    this.modelStore.setNextInstance(stageNumber)
    this.instanceId = currStage.currentInstance

    if (this.instanceId == 0)
      this.instanceId++
  },

  beforeUnmount() {
    this.modelStore.setNextInstance(stageNumber)
  },

  mounted() {
    if (!this.modelStore.model)
      this.modelStore.newModel()

    this.navigationStore.step(stageNumber)
    this.navigationStore.items[stageNumber].active = true

    var currStage = this.modelStore.getStage(stageNumber)
    this.modelStore.setNextInstance(stageNumber)
    this.instanceId = currStage.currentInstance

    if (currStage) {
      if (this.instanceId === 1) {
        this.modelStore.fillStageParams(stageNumber, 0, this.inputFieldsCommon)
        this.modelStore.fillStageParams(stageNumber, 1, this.inputFieldsOwn)
        this.ShowListParamsOwn(this.instanceId)
      }

      if (this.instanceId > 1) {
        this.lists = this.modelStore.getStageLists(stageNumber, 2)
        this.sortTheLists()
        this.ShowListParamsCommon(0)
        this.modelStore.setNextInstance(stageNumber)
        this.instanceId = currStage.currentInstance
        this.ShowListParamsOwn(this.instanceId - 1)
      }
    }
  },

  methods: {
    nextStep() {
      this.navigationStore.items[3].valid = true
      this.navigationStore.step(4)
      this.navigationStore.navigate(4)
    },

    IdPlusEins() {
      var currStage = this.modelStore.getStage(stageNumber)
      var list = this.modelStore.getListParams(stageNumber, this.instanceId)

      if (list.params[0].value !== "") {
        this.modelStore.setNextInstance(stageNumber)
        this.instanceId = currStage.currentInstance
        this.inputFieldsOwn.forEach(field => {
          field.value = ""
        })
        this.modelStore.fillStageParams(stageNumber, this.instanceId, this.inputFieldsOwn)
        this.lists = this.modelStore.getStageLists(stageNumber, 2)
        this.sortTheLists()
        this.reload()
      }
    },
    reload() {
      this.inputs_reload++
    },
    deleteItem(option) {
      if (this.modelStore.setListToDelete(stageNumber, option.id)) {
        this.lists = this.modelStore.getStageLists(stageNumber, 2)
        this.sortTheLists()
        this.instanceId = this.modelStore.getCurrentInstance(stageNumber)
        this.ShowListParamsOwn(this.instanceId)
      }
    },

    addItem(option) {
      if (this.modelStore.cancelListToDelete(stageNumber, option.id)) {
        this.lists = this.modelStore.getStageLists(stageNumber, 2)
        this.sortTheLists()
        this.instanceId = this.modelStore.getCurrentInstance(stageNumber)
        this.ShowListParamsOwn(this.instanceId)
      }
    },

    ShowListParamsOwn(option) {
      this.instanceId = parseInt(option)
      if (option.id) {
        this.instanceId = parseInt(option.id)
      } else {
        this.instanceId = option
      }
      var currStage = this.modelStore.getStage(stageNumber)
      if (currStage)
        currStage.currentInstance = this.instanceId
      var newList = []
      this.inputFieldsOwn.forEach(prop => {
        newList.push({
          id: prop.id,
          title: prop.title,
          type: prop.type,
          value: "",
          isRequired: prop.isRequired
        })
        var dataProp = this.modelStore.getParamValue(stageNumber, this.instanceId, prop.id);
        if (dataProp)
          newList[newList.length - 1].value = dataProp
      });
      this.inputFieldsOwn = newList;
    },

    ShowListParamsCommon(option) {
      this.instanceId = parseInt(option)
      if (option.id) {
        this.instanceId = parseInt(option.id)
      } else {
        this.instanceId = option
      }
      var currStage = this.modelStore.getStage(stageNumber)
      if (currStage)
        currStage.currentInstance = this.instanceId
      var newList = []
      this.inputFieldsCommon.forEach(prop => {
        newList.push({
          id: prop.id,
          title: prop.title,
          type: prop.type,
          value: "",
          isRequired: prop.isRequired
        })
        var dataProp = this.modelStore.getParamValue(stageNumber, 0, prop.id);
        if (dataProp)
          newList[newList.length - 1].value = dataProp
      });
      this.inputFieldsCommon = newList;
    },

    getSelected() {
      var selectedId = this.modelStore.getParamValue(stageNumber, this.instanceId, 9)
      if (selectedId) {
        var option = this.selectOptions.find(option => String(option.id) === selectedId)
        return option
      }
      return this.selectOptions[0]
    },

    // post and get then next step
    async postDataNextStep() {
      var model = this.modelStore.model;
      await httpPost(ApiNames.model_save, model);
      this.getData()
    },

    async getData() {
      var response = await httpGet(ApiNames.model_get, this.modelStore.model.id, this.modelStore.token)
      var data = response.data;
      this.modelStore.model = data;

      this.navigationStore.items[3].valid = true
      this.navigationStore.step(4)
      this.navigationStore.navigate(4)
    },

    async postDataPreview() {
      var model = this.modelStore.model;
      await httpPost(ApiNames.model_save, model);
      this.getDataPreview()
    },

    async getDataPreview() {
      var response = await httpGet(ApiNames.model_get, this.modelStore.model.id, this.modelStore.token)
      var data = response.data;
      this.modelStore.model = data;
      this.modelStore.showPreview();

      this.lists = this.modelStore.getStageLists(stageNumber, 2)
      this.sortTheLists()

      this.instanceId = this.modelStore.getCurrentInstance(stageNumber)

      this.ShowListParamsOwn(this.instanceId)
      this.ShowListParamsCommon(0)
      this.instanceId = this.modelStore.getCurrentInstance(stageNumber)
    },
    sortTheLists() {
      this.lists.sort(function (a, b) { return a.sorting - b.sorting });
    }
  }
};
</script>

<style scoped>
.base-card {
  width: 40vw;
  height: 65vh;
}

.cmp-list {
  margin-bottom: 1rem;
  list-style-type: none;
  max-height: 5rem;
  overflow-y: auto;
  border-radius: 4px;
  padding: 4px 0px;
  padding-left: 4px;
  box-shadow: var(--list-shadow);
}

.to_delete {
  text-decoration: line-through;
}

li {
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  margin-bottom: 2px;
}

li span {
  cursor: pointer;
}

li input:hover {
  transition: 0.2s;
  box-shadow: 2px 2px 5px var(--dark-gray);
  background-color: var(--mdblue-hover);
  border-color: var(--mdblue-hover);
}

li span:hover {
  color: var(--mdblue-hover);
}

.social-media-container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 90%;
  justify-content: space-around;
  overflow-y: hidden;
}

.content-left {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  justify-content: space-between;
  margin: auto;
}

.content-right {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 80%;
  justify-content: space-between;
  margin: auto;
}

.btn-left {
  display: flex;
  justify-content: left;
  width: 100%;
  margin-bottom: 1rem;
}

.btn-right {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

#preview {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  margin-bottom: 1rem;
}

p {
  color: var(--dark-gray);
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  max-width: fit-content;
}

.own-links {
  padding-bottom: 1.5rem;
}

.drop-down {
  width: 50%;
}

.del-btn {
  margin-right: .5rem;
}
</style>