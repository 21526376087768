<template>
  <div class="flow">

    <!-- <CheckIcon v-if="valid" class=" text-okay text-center" :size="15" /> -->
    <!-- <CircleIcon v-if="!valid" class=" text-gray-500 text-center" :size="15" /> -->
    

    <div v-bind:class="['icon-circle',
      { 'circle-gray': !valid, 'circle-blue': valid },
      { 'circle-active-shadow': active, 'cursor-pointer': available }]"
      @click.prevent="$emit('navigateTo')">

      <img height="25" v-bind:class="['margin-auto', { 'icon-black': !valid, 'icon-white': valid }]" :src="this.icon"
        alt="">
    </div>

    <div class="select-none">
      <!-- 
      <div v-bind:class="{ 'text-gray-500': !available }" class="text-center">{{ text }}
      </div> -->

      <div class="text-center text-sm text-wfgray">{{ value }}
      </div>

    </div>
  </div>
</template>

<script>
// import CheckIcon from 'vue-material-design-icons/CheckCircle.vue'
// import CircleIcon from 'vue-material-design-icons/CheckboxBlankCircleOutline.vue'

export default {
  name: "NavigationItem",
  components: {
    // CheckIcon,
    // CircleIcon,
  },
  emits: ['navigateTo'],
  props: {
    text: { type: String },
    value: { type: String },
    active: { type: Boolean, default: false },
    valid: { type: Boolean, default: false },
    available: { type: Boolean, default: false },
    icon: { type: String }
  },
  methods: {
    clicked() {
      if (this.$props.available)
        this.$emit('navigateTo')
    }
  },
  updated() {
    // this.changeStylesLine(this.value);
  }
}
</script>
<style scoped>
.flow {
  display: flex;
  flex-flow: column;
  justify-content: center;
  justify-items: center;
  font-size: .8rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: var(--background-gray) !important;
}

/* .flow:before {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background: var(--mdblue);
  left: 0;
  top: 30%;
  position: sticky;
  z-index: -1000;
} */

.icon-black {
  filter: invert(0%);
}

.icon-white {
  filter: invert(100%);
}

.text-center {
  align-self: center;
}

.icon-circle {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  /* background-color: aqua !important; */
}

.circle-blue {
  background-color: var(--mdblue);
}

.circle-gray {
  background-color: var(--background-gray);
}

.text-okay {

  color: var(--mdblue);
}

.cursor-pointer {

  cursor: pointer;
}

.bg-graylight {
  background: var(--white);
}

.circle-no-border {
  border: none;
}



.circle-active-shadow {
  box-shadow: 5px 5px 15px var(--dark-gray);
  /* border: 1px solid var(--dark-gray); */
}

.bg-blue {
  /* border: 1px solid var(--mdblue); */
  /* border-color:  */
  background: var(--white);

}

.margin-auto {
  margin: auto;
}
.flow .statur-bar{
  margin: 0;
  padding: 0;
}

</style>