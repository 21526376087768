import { createRouter, createWebHistory } from 'vue-router'
import Welcome from '../pages/Welcome.vue'
import GeneralData from '../pages/GeneralDataPage.vue'
import MoreLocations from '../pages/MoreLocationPage.vue'
import SocialMediaLinks from '../pages/SocialMediaLinksPage.vue'
import CompanyArticle from '../pages/CompanyArticlePage.vue'
import ProductArticle from '../pages/ProductArticlePage.vue'
import FinishPage from '../pages/FinishPage.vue'
import Main from '../components/Main/Main.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', name: 'home', component: Main },
    { path: '/welcome', name: 'welcome', component: Welcome },
    { path: '/generaldata', name: 'generaldata', component: GeneralData },
    { path: '/morelocations', name: 'morelocations', component: MoreLocations },
    { path: '/socialmedialinks', name: 'socialmedialinks', component: SocialMediaLinks },
    { path: '/companyarticle', name: 'companyarticle', component: CompanyArticle },
    { path: '/productarticle', name: 'productarticle', component: ProductArticle },
    { path: '/finishpage', name: 'finishpage', component: FinishPage },
  ],
})

export default router