<template>
  <base-card v-if="!fatalError" class="base-card" :hasPreview="true" @post="postDataPreview()" :hasTitle="true"
    title="Weitere Standorte" :logo="imageLocation">
    <div class="more-locations-container">
      <div class="li-container">
        <ul class="cmp-list" v-if="this.lists.length > 0 && this.lists[0].params[0].value !== ''">
          <li v-for="option in this.lists" :title="option.params[0].value" :id="option.id" :key="'A' + option.id">
            <span :class="{ to_delete: option.toDelete }" @click="ShowListParams(option)">{{ option.params[0].value
            }}</span>
            <div class="">
              <ListButton :imageString="pencilSvg" :hasImg="true" @click="ShowListParams(option)"></ListButton>
              <ListButton v-show="!option.toDelete" @click="deleteItem(option)" class="del-btn" type="button" title="x"
                :key="'B' + option.id" />
              <ListButton v-show="option.toDelete" @click="addItem(option)" class="del-btn" type="button" title="+"
                :key="'C' + option.id" />
            </div>
          </li>
        </ul>
      </div>
      <div class="form-container">
        <form action="">
          <div class="form-top">
            <input-field :key="this.inputs_reload" :id="inputFields[0].id" :title="inputFields[0].title"
              :type="inputFields[0].type" :isRequired="inputFields[0].isRequired" :listId="this.instanceId"
              v-model="inputFields[0].value" :value="inputFields[0].value" :stageId="2"></input-field>
          </div>
          <div class="div-horizontal">
            <input-field :key="this.inputs_reload" :id="inputFields[1].id" :title="inputFields[1].title"
              v-model="inputFields[1].value" :type="inputFields[1].type" :isRequired="inputFields[1].isRequired"
              :listId="this.instanceId" :value="inputFields[1].value" :stageId="2"></input-field>
            <input-field :key="this.inputs_reload" :id="inputFields[2].id" :title="inputFields[2].title"
              :type="inputFields[2].type" :isRequired="inputFields[2].isRequired" :listId="this.instanceId"
              :value="inputFields[2].value" :stageId="2"></input-field>
          </div>
          <div class="div-horizontal">
            <input-field :key="this.inputs_reload" :id="inputFields[4].id" :title="inputFields[4].title"
              :type="inputFields[4].type" :isRequired="inputFields[4].isRequired" :stageId="2" :listId="this.instanceId"
              :value="inputFields[4].value"></input-field>
            <input-field :key="this.inputs_reload" :id="inputFields[3].id" :title="inputFields[3].title"
              :type="inputFields[3].type" :isRequired="inputFields[3].isRequired" :stageId="2" :listId="this.instanceId"
              :value="inputFields[3].value"></input-field>
          </div>
          <input-field :key="this.inputs_reload" :id="inputFields[5].id" :title="inputFields[5].title"
            :type="inputFields[5].type" :isRequired="inputFields[5].isRequired" :stageId="2" :listId="this.instanceId"
            :value="inputFields[5].value"></input-field>
          <input-field :key="this.inputs_reload" :id="inputFields[6].id" :title="inputFields[6].title"
            :type="inputFields[6].type" :isRequired="inputFields[6].isRequired" :stageId="2"
            hint="(Format: +49 234 30703-0)" :listId="this.instanceId" :value="inputFields[6].value"></input-field>
          <input-field :key="this.inputs_reload" :id="inputFields[7].id" :title="inputFields[7].title"
            :type="inputFields[7].type" :isRequired="inputFields[7].isRequired" hint="(Format +49 234 30703-99)"
            :stageId="2" :listId="this.instanceId" :value="inputFields[7].value"></input-field>
          <input-field :key="this.inputs_reload" :id="inputFields[8].id" :title="inputFields[8].title"
            :type="inputFields[8].type" :isRequired="inputFields[8].isRequired" hint="(info@... / vertrieb@...)"
            :stageId="2" :listId="this.instanceId" :value="inputFields[8].value"></input-field>
          <input-field :key="this.inputs_reload" :id="inputFields[9].id" :title="inputFields[9].title"
            :type="inputFields[9].type" :isRequired="inputFields[9].isRequired" :stageId="2" :listId="this.instanceId"
            :value="inputFields[9].value" hint="(Format http://www... / https://www...)"></input-field>
        </form>
      </div>
      <div class="button-container">
        <one-button title="Standort hinzufügen" @click.prevent="IdPlusEins()"></one-button>
        <one-button id="btn-weiter" title="Weiter/Überspringen" v-on:click.prevent="postDataNextStep()"></one-button>
      </div>
    </div>
  </base-card>
  <h1 v-if="fatalError">Keine Daten gefunden!</h1>
</template>

<script>
import BaseCard from '../components/BasicComponents/BaseCard.vue';
import InputField from "../components/BasicComponents/InputField.vue";
import OneButton from "../components/BasicComponents/OneButton.vue";
import { useNavigationStore } from '../stores/navigation'
import imageLocation from '../assets/desktop/ort.svg'
import { useModelStore } from '../stores/model'
import ListButton from '../components/BasicComponents/ListButton.vue'
import { ApiNames, httpGet, httpPost } from '../libs/httpHelper'
import PencilSvg from '../assets/desktop/pencil.svg'

const stageNumber = 2;

export default {
  components: { InputField, OneButton, BaseCard, ListButton },
  data() {
    return {
      stageId: stageNumber,
      renderComponent: true,
      imageLocation: imageLocation,
      pencilSvg: PencilSvg,
      navigationStore: useNavigationStore(),
      modelStore: useModelStore(),
      inputs_reload: 0,
      fatalError: false,
      lists: [
        {
          id: -1,
          sorting: -1,
          params: [{
            id: 0,
            type: "",
            title: "",
            value: "",
            valid: false
          }]
        }
      ],
      instanceId: 1,
      inputFields: [{
        id: 1,
        title: "Firmenname",
        type: "text",
        value: "",
        isRequired: true
      }, {
        id: 2,
        title: "Straße",
        type: "text",
        value: "",
        isRequired: true
      }, {
        id: 3,
        title: "Hausnummer",
        type: "text",
        value: "",
        isRequired: true
      }, {
        id: 4,
        title: "Ort",
        type: "text",
        value: "",
        isRequired: true
      }, {
        id: 5,
        title: "PLZ",
        type: "text",
        value: "",
        isRequired: true
      }, {
        id: 6,
        title: "Land",
        type: "text",
        value: "",
        isRequired: true
      }, {
        id: 7,
        title: "Telefonnummer",
        type: "text",
        value: "",
        isRequired: true
      }, {
        id: 8,
        title: "Faxnummer",
        type: "text",
        value: "",
        isRequired: false
      }, {
        id: 9,
        title: "Allgemeine E-Mail-Adresse",
        type: "email",
        value: "",
        isRequired: true
      }, {
        id: 10,
        title: "Homepage",
        type: "url",
        value: "",
        isRequired: true
      }],
    };
  },

  beforeMount() {
    if (!this.modelStore.model) {
      this.fatalError = true
    }
    if (!this.modelStore.checkValidModel())
      this.fatalError = true

    var currStage = this.modelStore.getStage(stageNumber)
    if (!currStage)
      this.modelStore.createStage(stageNumber)

    this.modelStore.setNextInstance(stageNumber)
    this.instanceId = currStage.currentInstance
  },

  mounted() {
    this.navigationStore.step(stageNumber)
    this.navigationStore.items[stageNumber].active = true

    var currStage = this.modelStore.getStage(stageNumber)
    if (currStage) {
      if (this.instanceId < 2) {
        this.modelStore.fillStageParams(stageNumber, this.instanceId, this.inputFields)
      }
      if (this.instanceId > 1) {
        this.ShowListParams(this.instanceId - 1)
      }
      this.lists = this.modelStore.getStageLists(stageNumber, 1)
      this.sortTheLists();
    }
  },

  beforeUnmount() {
    var currStage = this.modelStore.getStage(stageNumber)
    if (currStage) {
      currStage.currentInstance = this.instanceId
    }
  },

  methods: {
    nextStep() {
      this.navigationStore.items[2].valid = true
      this.navigationStore.step(3)
      this.navigationStore.navigate(3)
    },

    IdPlusEins() {
      var currStage = this.modelStore.getStage(stageNumber)
      var list = this.modelStore.getListParams(stageNumber, this.instanceId)

      // nur wenn mindestens Name eingetragen ist dard ein Standort zugefügt werden
      if (list.params[0].value !== "") {
        this.modelStore.setNextInstance(stageNumber)
        this.instanceId = currStage.currentInstance
        this.clearInputFields()
        this.modelStore.fillStageParams(2, this.instanceId, this.inputFields)
        this.lists = this.modelStore.getStageLists(stageNumber, 1)
        this.sortTheLists();
        this.ShowListParams(this.instanceId)
        this.reload();
      }
    },

    reload() {
      this.inputs_reload++
    },

    clearInputFields() {
      this.inputFields.forEach(field => {
        if (field.id === 1) {
          field.value = "Standortname"
        }
        field.value = ""
      })
    },

    deleteItem(option) {
      if (this.modelStore.setListToDelete(stageNumber, option.id)) {
        this.lists = this.modelStore.getStageLists(stageNumber, 1)
        this.sortTheLists();
        this.instanceId = this.modelStore.getCurrentInstance(stageNumber)
        this.ShowListParams(this.instanceId)
      }
    },

    addItem(option) {
      if (this.modelStore.cancelListToDelete(stageNumber, option.id)) {
        this.lists = this.modelStore.getStageLists(stageNumber, 1)
        this.sortTheLists();
        this.instanceId = this.modelStore.getCurrentInstance(stageNumber)
        this.ShowListParams(this.instanceId)
      }
    },

    ShowListParams(option) {
      if (option.id) {
        this.instanceId = parseInt(option.id)
      } else {
        this.instanceId = option
      }
      var currStage = this.modelStore.getStage(stageNumber)
      if (currStage)
        currStage.currentInstance = this.instanceId

      var newList = []
      this.inputFields.forEach(prop => {
        newList.push({
          id: prop.id,
          title: prop.title,
          type: prop.type,
          value: "",
          isRequired: prop.isRequired
        })
        var dataProp = this.modelStore.getParamValue(stageNumber, this.instanceId, prop.id);
        if (dataProp)
          newList[newList.length - 1].value = dataProp
      });
      this.inputFields = newList;
    },


    // post and get then next step
    async postDataNextStep() {
      var model = this.modelStore.model;
      await httpPost(ApiNames.model_save, model);
      this.getData()
    },
    async getData() {

      var response = await httpGet(ApiNames.model_get, this.modelStore.model.id, this.modelStore.token)
      var data = response.data;
      this.modelStore.model = data;

      this.navigationStore.items[2].valid = true
      this.navigationStore.step(3)
      this.navigationStore.navigate(3)
    },


    // post and get then redirect preview page
    async postDataPreview() {
      var model = this.modelStore.model;
      await httpPost(ApiNames.model_save, model);
      this.getDataPreview()
    },

    async getDataPreview() {//async
      //console.log(this.modelStore.model.id)
      var response = await httpGet(ApiNames.model_get, this.modelStore.model.id, this.modelStore.token)
      var data = response.data;
      this.modelStore.model = data;
      //console.log(data)
      this.modelStore.showPreview();

      this.lists = this.modelStore.getStageLists(stageNumber, 1)
      //this.lists.sort(function(a, b) {return a.sorting-b.sorting});
      this.sortTheLists();
      var currStage = this.modelStore.getStage(stageNumber)
      this.instanceId = currStage.currentInstance
      this.ShowListParams(currStage.currentInstance)
    },

    sortTheLists() {
      this.lists.sort(function (a, b) { return a.sorting - b.sorting });
    }
  },
};
</script>

<style scoped>
.base-card {
  width: 40vw;
  height: fit-content;
}

.more-locations-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  height: 85%;
}

.form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 1.5rem;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

#preview {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  margin-bottom: 1rem;
}

.div-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cmp-list {
  margin-bottom: 1rem;
  list-style-type: none;
  max-height: 5rem;
  overflow-y: auto;
  border-radius: 4px;
  padding: 4px 0px;
  padding-left: 4px;
  box-shadow: var(--list-shadow);
}

li {
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  margin-bottom: 2px;
}

li span {
  cursor: pointer;
}

li input:hover {
  transition: 0.2s;
  box-shadow: 2px 2px 5px var(--dark-gray);
  background-color: var(--mdblue-hover);
  border-color: var(--mdblue-hover);
}

li span:hover {
  color: var(--mdblue-hover);
}

.to_delete {
  text-decoration: line-through;
}

.input-container.input-Straße {
  width: 66%;
  margin: 0;
}

.input-container.input-Hausnummer {
  width: 33%;
  margin: 0;
}

.input-container.input-Ort {
  width: 66%;
}

.input-container.input-PLZ {
  width: 33%;
}

.input-container.input-Land {
  margin: 0;
}


.input-container label.active {
  position: absolute;
  top: -10px;
  font-size: 0.75rem;
}

.country-select {
  width: 35%;
}

.del-btn {
  margin-left: .5rem;
}
</style>