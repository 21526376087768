<template>
  <main id="Main">
    <base-card class="base-card">
      <div class="content-container">
        <h2 class="headText mb-2">MDESIGN Profile-Manager</h2>
        <div>
          <p><br>Mit der Platzierung Ihres Unternehmens in der MDESIGN Konstruktionssoftware haben Sie eine gute
            Entscheidung getroffen.</p>
          <p><br>Um Ihr Unternehmen und Produkte optimal darzustellen, benötigen wir Daten für Ihr Firmen- und
            Produktprofil.</p>
          <p><br>Für die Erstellung Ihres Profils nutzen Sie einfach, schnell und unkompliziert den MDESIGN
            Profile-Manager.</p>
          <p><br>Bei Fragen erreichen Sie unseren Kundenservice unter +49 234 30703-71 oder <a
              href="mailto:marketing-services@mdesign.de">marketing-services@mdesign.de</a>.</p>
          <p><br>Viel Erfolg bei der Erstellung Ihres Firmen- und Produktprofils.</p>
          <P><br>Ihr MDESIGN-Team</P>
        </div>
        <!-- <div class="flex image-box">
          <img src="@/assets/eyecatcher-small.jpg" alt="eye catcher" class="image" />
        </div> -->
        <one-button title="Jetzt starten" v-on:click.prevent="this.getData()"></one-button>
      </div>
    </base-card>
  </main>
</template>

<script>

import OneButton from '../components/BasicComponents/OneButton.vue';
import { useNavigationStore } from '../stores/navigation'
import BaseCard from '../components/BasicComponents/BaseCard.vue'
import { useModelStore } from '../stores/model'
import { ApiNames, httpGet } from '../libs/httpHelper'
import Swal from 'sweetalert2';

export default {
  name: "Welcome",
  components: {
    OneButton,
    BaseCard
  },
  data() {
    return {
      navigationStore: useNavigationStore(),
      modelStore: useModelStore(),
      userId: "",
      token:""
    }
  },
  mounted() {
    
    //this.uid = this.$route.fullPath.split('=')[1]
    this.uid = this.$route.query.id
    this.token = this.$route.query.token
    //console.log("1 ",this.$route.query.id)
    
    this.uid = decodeURIComponent(this.uid)
    

    if(this.uid !=="Falsches Passwort"){
      this.modelStore.newModel(this.uid)
      this.modelStore.setToken(this.token)


      this.navigationStore.step(0)
      this.navigationStore.items[0].active = true
  
      this.navigationStore.items[0].valid = false
      this.navigationStore.items[1].valid = false
      this.navigationStore.items[2].valid = false
      this.navigationStore.items[3].valid = false
      this.navigationStore.items[4].valid = false
      this.navigationStore.items[5].valid = false
  
      this.navigationStore.items[0].available = false
      this.navigationStore.items[1].available = false
      this.navigationStore.items[2].available = false
      this.navigationStore.items[3].available = false
      this.navigationStore.items[4].available = false
      this.navigationStore.items[5].available = false
      this.navigationStore.items[6].available = false
    }else{window.close()}
  },
  methods:
  {
    nextStep() {
      this.navigationStore.items[0].valid = true
      this.navigationStore.step(1)
      this.navigationStore.navigate(1)

      this.modelStore.getStage(0).valid = true
      this.modelStore.getStage(0).complete = true
    },
    async getData() {
      var modelData = {
        id: "",
        version: 0,
        name: 0,
        progress: 0,
        stages: [],
      }
      modelData = this.modelStore.newModelInstance(this.uid)
      if(modelData)
        this.modelStore.token = this.token;
      
      var response = await httpGet(ApiNames.model_get, modelData.id, this.token)

      var data = response.data;

      this.modelStore.model = data;

      if(data.valid !== true){
        Swal.fire({
                    title: '',
                    text: 'Ihr Link ist abgelaufen',
                    icon: 'error',
                    confirmButtonText: 'Bestätigen',
                    confirmButtonColor: "#00a1e6"
                }).then((result)=>{
                  window.close();
                })
                  
                
      }


      if (response && data.valid === true) {
        this.modelStore.createStage(0)
        this.navigationStore.items[0].valid = true
        this.navigationStore.items[0].complete = true
        this.navigationStore.navigate(1)
      }
    }
  }
}
</script>
<style scoped>
.image-box {
  height: fit-content;
  width: fit-content;
  object-fit: cover;
}

#Main {
  display: flex;
  flex-direction: column;
}

.base-card {
  width: 40vw;
  height: fit-content;
}

.content-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 70%;
  margin: 2rem;
}

.single-button-container {
  display: flex;
  justify-content: right;
}

.headText {
  text-align: center;
}</style>
