<template>
  <div class="editor-container">
    <label for="">{{ this.title }}</label>
    <div class="editor" id="editor">
      <QuillEditor
        theme="snow"
        :toolbar="this.toolbarOptions"
        :contentType="'html'"
        v-model:content="this.content"
        :value="this.content"
        :id="this.id"
        :listId="this.listId"
        :stageId="this.stageId" />
    </div>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useModelStore } from "../../stores/model";

export default {
  components: { QuillEditor, },
  props: {
    id: Number,
    title: String,
    listId: Number,
    stageId: Number,
    value: String
  },
  data() {
    return {
      content: "",
      modelStore: useModelStore(),
      pasted: false,
      toolbarOptions: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ],
      reloadCounter: 0
    };
  },
  methods: {
    contentPasted() {
      this.pasted = true
      this.content = this.modelStore.getParamValue(this.stageId, this.listId, this.id)
    }
  },
  mounted() {
    if (this.value === "") {
      this.content = ""
    } else {
      this.content = this.value;
    }
  },
  watch: {
    content(newVal) {
      this.modelStore.updateParamValue(this.stageId, this.listId, 3, newVal)
      this.content = newVal
    },
    value(newValue) {
      if (newValue === "") {
        this.content = "<br>"
        this.modelStore.updateParamValue(this.stageId, this.listId, 3, this.content)
      } else {
        this.content = newValue
        this.modelStore.updateParamValue(this.stageId, this.listId, 3, this.content)
      }
    }
  }
};
</script>

<style scoped>
.editor {
  height: 200px;
  margin-top: 15px;
  margin-bottom: 3.2rem;
}

.editor-container {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  height: fit-content;
  color: #4e4e4e;
}

label {
  border-bottom: 2px solid var(--light-gray);
  max-width: fit-content;
  color: var(--dark-gray);
  margin-bottom: 5px;
}
</style>