<template>
  <!--UPLOAD-->
  <div class="upload-container">
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <h3>{{ this.ueberschrift }}</h3>
      <div class="dropbox">
        <input type="file" :name="uploadFieldName" :disabled="isSaving" max-file-size="1024"
          @change="filesChange($event.target.name, $event.target.files, true); fileCount = $event.target.files.length;"
          accept="image/*" class="input-file" :id="this.id" :listId="this.listId" :title="this.title"
          :stageId="this.stageId" :file="this.file">
        <p v-if="isInitial">
          Es sind die Formate .jpeg, .jpg oder .png möglich und sollten vollfarbig und mit transparentem Hintergrund
          bereitgestellt werden. Die Datai darf nicht größer sein als {{ this.uploadLimit }} MB.
        </p>
        <p v-if="isSaving">
          Uploading {{ fileCount }} files...
        </p>
      </div>
    </form>
    <!--SUCCESS-->
    <div v-if="isSuccess">
      <p>
        <a href="javascript:void(0)" @click=" reset()">Neues Bild hochladen</a>
      </p>
      <ul class="list-unstyled">
        <li v-for=" item  in  uploadedFiles " :key="item.id">
          <img :src="item.url" class="img-responsive img-thumbnail" :alt="item.originalName">
        </li>
      </ul>
    </div>
    <!--FAILED-->
    <div v-if="isFailed">
      <h2>Uploaded failed.</h2>
      <p>
        <a href="javascript:void(0)" @click=" reset()">Try again</a>
      </p>
      <pre>{{ uploadError }}</pre>
    </div>
  </div>
</template>

<script>
// swap as you need
import { upload, dataURLtoFile, resizeImage } from './file-upload.fake.service';
import { useModelStore } from '../../stores/model'
import Swal from 'sweetalert2';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];

export default {
  props: {
    id: Number,
    title: String,
    listId: Number,
    stageId: Number,
    value: String,
    file: Object,
    ueberschrift: { type: String, default: "Upload images:" }
  },
  name: 'Upload',
  data() {
    return {
      imageSource: null,
      uploadedFiles: { fileName: "", id: "", originalName: "", url: "" },
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      modelStore: useModelStore(),
      uploadLimit: 2.5,
      isPNG: this.Validate([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]),
      //source :https://medium.com/frontend-canteen/how-to-detect-file-type-using-javascript-251f67679035
      isJPEG: this.Validate([0xff, 0xd8, 0xff]),
    }
  },

  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;

      upload(formData)
        //  .then(wait(1500)) // DEV ONLY: wait for 1.5s 
        .then(x => {
          this.uploadedFiles = [].concat(x);

          if (this.uploadedFiles.length > 0) {
            this.modelStore.updateParamValue(this.stageId, this.listId, this.id, this.uploadedFiles[0].url)
            this.modelStore.updateParamTitle(this.stageId, this.listId, this.id, this.uploadedFiles[0].fileName)
          }
          this.currentStatus = STATUS_SUCCESS;
        })
        .catch(err => {
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    Validate(headers) {

      return (buffers, options = {
        offset: 0
      }) => headers.every((header, index) => header === buffers[options.offset + index]);
    },

    readBuffer(file, start = 0, end = 2) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        }
          ;
        reader.onerror = reject;
        reader.readAsArrayBuffer(file.slice(start, end));
      }
      );
    },
    stringToBytes(string) {
      return [...string].map((character) => character.charCodeAt(0));
    },
    async filesChange(fieldName, fileListIn, checkFiles) {
      // handle file changes
      var fileList = [...fileListIn];

      var buffers = await this.readBuffer(fileList[0], 0, 8);
      var uint8Array = new Uint8Array(buffers);

      if (checkFiles)
        if ((this.isPNG(uint8Array) && fileList[0].type != "image/png") || (this.isJPEG(uint8Array) && fileList[0].type != "image/jpeg")) {

          var addMsg = "Bitte korrigieren Sie die Dateiendung"
          var msg = `Das Format der Datei <br>'<b>${fileList[0].name}</b>' ist：<br><b>${this.isPNG(uint8Array) ? ".png" : fileList[0].type}</b>.</br>`

          if (this.isJPEG(uint8Array) && fileList[0].type != "image/jpg")
            msg = `Das Format der Datei <br>'<b>${fileList[0].name}</b>' ist：<br><b>${this.isJPEG(uint8Array) ? ".jpeg" : fileList[0].type}</b>.</br>`

          Swal.fire({
            title: 'Falsches Format',
            html: msg + addMsg,
            icon: 'warning',
            //showCancelButton: true,
            confirmButtonColor: '#00a1e6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Abbrechen',
            confirmButtonText: 'Bestätigen'
          }).then((result) => {
            if (result.isConfirmed) {

              // fileList[0].type = 'image/png'; 
              //fileList[0].name = fileList[0].name.replace('.jpg', 'png'); 

              //  Swal.fire(
              //    'Geändert!',
              //    'Ihre Datei wurde angepasst.',
              //    'success'
              //   )
              this.reset()
              return;
            } else
              this.reset()
            return;
          });
          this.reset()
          return;
        }

      if (!fileList || fileList[0] === undefined)
        return

      // scale down the Image to prevent localStorage overflow
      var newImg = await resizeImage(fileList[0], 150, 100)
      if (newImg)
        fileList[0] = newImg

      const formData = new FormData();
      if (!fileList.length) return;
      // append the files to FormData

      var filesize = ((fileList[0].size / 1024) / 1024).toFixed(4); // MB

      if (checkFiles)
        if (filesize > this.uploadLimit) {
          Swal.fire({
            title: 'Dateigröße',
            text: 'Die Dateigröße beträgt ' + Math.round(filesize) + ' MB. ' + ' Die Datai darf nicht größer sein als ' + this.uploadLimit + ' MB.',
            icon: 'error',
            confirmButtonText: 'Bestätigen',
            confirmButtonColor: "#00a1e6"
          }).then(() => {
            return
          });
          return;
        }
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name);
        });


      // save it
      if (checkFiles) {
        if (!validImageTypes.includes(fileList[0].type)) {
          Swal.fire({
            title: 'Falsches Format',
            text: 'Bitte nur Dateien vom Format .jpeg, .jpg oder .png hochladen',
            icon: 'error',
            confirmButtonText: 'Bestätigen',
            confirmButtonColor: "#00a1e6"
          }).then(() => {
            return
          });
          return;
        } else {
          this.save(formData);

        }
      } else
        this.save(formData);
    },

    changeImageFromModel() {
      var dataUrl = this.modelStore.getParamValue(this.stageId, this.listId, this.id)
      if (!dataUrl)
        return
      var name = this.modelStore.getParamTitle(this.stageId, this.listId, this.id)
      if (!name)
        return
      var testFile = dataURLtoFile(dataUrl, name);
      if (testFile) {
        var filelist = []
        filelist.push(testFile)
        this.filesChange("photos", filelist, false)
      }
    }
  },

  mounted() {
    this.reset();
    this.changeImageFromModel()
  },

  watch: {
    file() {
      this.reset()
      this.changeImageFromModel()
    }
  }
}
</script>

<style scoped>
h3 {
  text-align: center;
  margin-bottom: 1rem;
  color: var(--dark-gray);
  border-bottom: 2px solid var(--light-gray);
  width: fit-content;
}

li {
  list-style-type: none;
}

.dropbox {
  outline: 2px dotted grey;
  outline-offset: -10px;
  background: var(--light-gray);
  border-radius: 15px;
  box-shadow: 2px 2px 15px var(--light-gray);
  color: dimgray;
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
}

.input-file {
  position: absolute;

  opacity: 0;
  width: 95%;
  height: 100px;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
}

.dropbox p {
  margin: 0;
  color: var(--dark-gray);
  font-size: 1rem;
  text-align: center;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

a {
  color: var(--mdblue);
  text-decoration: none;
}

a:hover {
  color: var(--mdblue-hover);
}
</style>