<template>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false" :stageId="stageId" :listId="this.listId"
        :id="this.id">
        <div class="selected" :class="{ open: open }" @click="open = !open">
            <span @blur="$emit('some-event', selected)" id="selected-item-id">{{ selected.title }}</span>
        </div>
        <div class="items" :class="{ selectHide: !open }">
            <div v-for="(option, i) of  options " :key="i" @click="selected = option;
            open = false;
            $emit('input', option);
                         ">
                {{ option.title }}
            </div>
        </div>
    </div>
</template>

<script>
import { useModelStore } from '../../stores/model'
export default {
    emits: {
        'input': null,
        'some-event': null
    },
    props: {
        id: Number,
        stageId: Number,
        listId: { type: Number, default: 0 },
        capture: {
            type: String,
            default: "Überschrift"
        },
        selectedOption: {
            id: Number,
            title: String
        },
        options: {
            type: Array,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: null,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            selected: this.default
                ? this.default
                : this.options.length > 0
                    ? this.options[0]
                    : null,
            open: false,
            modelStore: useModelStore(),
        };
    },
    methods: {

    },
    watch: {
        selected(x) {
            this.modelStore.updateParamValue(this.stageId, this.listId, this.id, String(x.id));
        },

        selectedOption(x) {
            this.selected = x
        }
    },

    mounted() {
        this.selected = this.selectedOption
        this.$emit("input", this.selected);
    },
};
</script>

<style scoped>
.custom-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    line-height: 1.6rem;
}

.custom-select .selected {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid var(--light-gray);
    color: #000;
    padding-left: .5rem;
    cursor: pointer;
    user-select: none;
}

.custom-select .selected.open {
    border: 1px solid var(--mdblue);
    border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
    position: absolute;
    content: "";
    top: .8em;
    right: .5em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: var(--mdblue) transparent transparent transparent;
}

.custom-select .items {
    color: #000;
    border-radius: 0px 0px 6px 6px;
    overflow: hidden;
    border-right: 1px solid var(--mdblue);
    border-left: 1px solid var(--mdblue);
    border-bottom: 1px solid var(--mdblue);
    position: absolute;
    background-color: #fff;
    left: 0;
    right: 0;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
    max-height: 20rem;

}

.custom-select .items div {
    color: #000;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
}

.custom-select .items div:hover {
    background-color: var(--mdblue);
    color: #fff;
}

.selectHide {
    display: none;
}
</style>