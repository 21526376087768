<template>
    <div 
        id="ButtonMobile"
        @click="$emit('click-button')"
    >
        <div 
            class="line"
            :style="{ 
                top: lineTop.top, 
                transform: lineTop.transform,
                backgroundColor: lineTop.backgroundColor,
            }"
        >
        </div>
        <div 
            class="line"
            :style="{
                transform: lineCenter.transform,
                backgroundColor: lineCenter.backgroundColor,
            }"
        >
        </div>
        <div 
            class="line"
            :style="{
                bottom: lineBottom.bottom,
                transform: lineBottom.transform,
                backgroundColor: lineBottom.backgroundColor,
            }"
        >
        </div>
    </div>
</template>

<script>

    export default {
        name: "ButtonMobile",
        components: {

        },
        props: {
            value: {
                require: true,
                type: Boolean
            }
        },
        data() {
            return {
                lineTop: {
                    top: "0%",
                    transform: "",
                    backgroundColor: "black",
                    },
                lineCenter: {
                    transform: "",
                    backgroundColor: "black",

                },
                lineBottom: {
                    bottom: "0%",
                    transform: "",
                    backgroundColor: "black",
                }
            }
        },
        methods: {
            changeStylesLine(value) {
                if(value) {
                    this.lineTop.top = "50%",
                    this.lineTop.transform = "rotate(45deg)";
                    this.lineTop.backgroundColor = "white";
                    this.lineCenter.transform = "rotate(45deg)",
                    this.lineCenter.backgroundColor = "black";

                    this.lineBottom.bottom = "50%",
                    this.lineBottom.transform = "rotate(-45deg)";
                    this.lineBottom.backgroundColor = "black";
                    

                } else {
                    this.lineTop.top = "0%",
                    this.lineTop.transform = "";
                    this.lineTop.backgroundColor = "black";

                    this.lineCenter.transform = "",
                    this.lineCenter.backgroundColor = "black";

                    this.lineBottom.bottom = "0%",
                    this.lineBottom.transform = "";
                    this.lineBottom.backgroundColor = "black";

                }
            }
        },
        updated() {
            this.changeStylesLine(this.value);
        }
    }

</script>

<style scoped>
    div#ButtonMobile {
        width: 50px;
        height: 25px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        margin-right: 7.5vw;
    }

    div#ButtonMobile > div.line {
        width: 45px;
        height: 2px;

        background-color: var(--black);

        position: relative;

        transition: all 200ms;
    }

</style>