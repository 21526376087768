import axios from 'axios'

export const ApiNames = {
  model_save: '/modelsave',
  model_get: '/modelget',
  decr_id_get: '/decryptId',
  authorization: "https://my.mdesign.de/auth/api/authorization",
  sendMail: "https://mailservice.mdesign.online/api/sendemail"
  // sendMail: "https://my.mdesign.de/email/api/sendemail"
}

export const customConfig = {
  headers: {
    "Content-Type": "application/json",   //<-------IMPORTANT
  },
};


function getApiServerUrl() {
  var url = "https://datatool.mdesign.info/data/api" // neue prod Adresse
  //var url ="https://82.165.162.79/data/api" // Test Maschine neue URL
  //var url = "https://localhost:44320/api"
  return url
}

export async function httpGet(apiName, params, token, headers = {}) {
  try {
    const apiUrl = apiName.startsWith('http') ? apiName : getApiServerUrl() + apiName

    return await axios
      .get(apiUrl, {
        headers, params: {
          id: params,
          token: token
        }
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
  } catch (error) {
    return undefined
  }
}
export async function httpGetByUrl(apiUrl, params, headers = {}) {
  try {
    let config = {
      headers: headers,
      params: params,
    };

    return await axios
      .get(apiUrl, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        //console.log(error);
        return error.response;
      });
  } catch (error) {
    return undefined;
  }
}


export async function httpPostAuth(apiName, data, token) {
  const headerConfig = { headers: { Authorization: `Bearer ${token}` } };
  return await httpPost(apiName, data, { ...headerConfig });
}

export async function httpPost(apiName, data, config) {
  try {
    const apiUrl = apiName.startsWith('http') ? apiName : getApiServerUrl() + apiName

    return await axios
      .post(apiUrl, data, config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        //console.log(error)
        return error.response
      })
  } catch (error) {
    //console.log(error)
    return undefined
  }
}