<template>
  <footer id="Footer">
      <div id="side-bar-two-sides">
  
          <nav id="nav-bar-footer" >
              <ul id="list-items">
                  <li
                      class="item-text-link"
                      v-for="item in navBarFooterLinks"
                      :key="item.id"
                      >
                      <a class="link-social-media" target="_blank" :href="item.href">
                          {{ item.text }}
                      </a>
                  </li>
              </ul>
          </nav>
          <span id="rights-text">
              ©<span id="year"></span> MDESIGN.  Alle Rechte vorbehalten.
        </span>
      </div>
  </footer>
</template>

<script>

  export default {
      name: "Footer",
      components: {
      },
      data() {
          return {
            navBarFooterLinks: [
                  {
                      id: 1,
                      text: "Kontakt",
                      href: "https://www.mdesign-marketing.de/kontakt/"
           
                  },
                  {
                      id: 2,
                      text: "AGB",
                      href: "https://www.mdesign-marketing.de/agb/"
                  },
                  {
                      id: 3,
                      text: "Datenschutzerklärung",
                      href: "https://www.mdesign-marketing.de/datenschutz/"
                  },
                  {
                      id: 4,
                      text: "Widerrufsbelehrung",
                      href:"https://www.mdesign-marketing.de/widerrufsbelehrung/"

                  },
                  {
                      id: 5,
                      text: "Impressum",
                      href:"https://www.mdesign-marketing.de/impressum/"
                  }
                 
              ]
             
          }
      },
      mounted(){
          document.getElementById("year").innerHTML = new Date().getFullYear();
      }
  }

</script>

<style scoped>

  /* footer#Footer {
      width: 100%;
      height: 42px;
      background-color: var(--gray);
      justify-content: center;
      position: fixed;
  } */
  h1 {
      color: var(--white);
      margin-top: 1rem;
      text-decoration: none;
      font-family: 'Roboto-Regular', sans-serif;
      font-weight: 700 ;
  }
  div#side-bar-two-sides {
      width: 80%;
      height: inherit;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      /* display: grid;
      grid-template-areas: 
          "navBar rights"
      ; */
  }

  nav#nav-bar-footer {
      grid-area: navBar;
      justify-self: flex-start;
  }

  img#logo-footer,
  div#area-social-media,
  nav#nav-bar-footer,
  span#year,
  span#rights-text
  {
      align-self: center;
  }



  footer#Footer,
  div#area-social-media,
  ul#list-items 
  {
      display: flex;
  }

  div#area-social-media,
  ul#list-items 
  {
      justify-content: space-between;
      align-items: center;
  }

  ul#list-items {
      width: 30vw;
  }

  li {
      list-style-type: none;
  }

  a.link-social-media {
      color: var(--white);
      justify-content: center;
  }
  a.link-social-media:hover {
    text-decoration: none;
  }
  span.text-link {
      color: var(--white);
      font-family: "Roboto-Regular", sans-serif;

  
  }
  span#year,
  span#rights-text {
      color: var(--white);
      font-family: "Roboto";
      font-size: 12.5px;
      grid-area: rights;
      justify-self: flex-end;
      align-self: center;
  }

  @media screen and (max-width: 1078px) {
      li.item-text-link {
          margin-right: 10px;
      }

      li.item-text-link:last-of-type {
          margin-right: 0;
      }
  }

  @media screen and (max-width: 818px) {
      footer#Footer {
          /* height: 25rem; */
          height: 10vh;
          font-size: 15px;
      }

      div#side-bar-two-sides {
          display: flex;
          flex-direction: column;
          /* flex-direction: row; */
          justify-content: space-evenly;
          align-items: center;
      }

      ul#list-items {
          flex-direction: column;
          height: 10rem;
      }

      li.item-text-link {
          margin-right: 0;
      }
      span#year,
      span#rights-text {
          font-size: 15px;
      }
  }

  @media screen and (max-width: 450px) {
      span#year,
      span#rights-text {
          font-size: 10px;
      }
  }

</style>