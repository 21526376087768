<template>
  <base-card v-if="!fatalError" class="base-card" :logo="imageProductArticle" :hasPreview="true" @post="postDataPreview()"
    title="Fügen Sie bis zu 5 Produkte hinzu" :hasTitle="true">
    <div class="product-article-container">
      <div class="li-container">
        <ul class="cmp-list" v-if="this.lists.length > 0 && this.lists[0].params[0].value !== ''">
          <li v-for="option in this.lists" :title="option.params[0].value" :id="option.id" :key="'A' + option.id">
            <span :class="{ to_delete: option.toDelete }" @click="ShowListParams(option)">{{ option.params[0].value }}
            </span>
            <div>
              <ListButton :imageString="pencilSvg" :hasImg="true" @click="ShowListParams(option)"></ListButton>
              <ListButton v-show="!option.toDelete" @click="deleteItem(option)" class="del-btn" type="button" title="x"
                :key="'B' + option.id" />
              <ListButton v-show="option.toDelete" @click="addItem(option)" class="del-btn" type="button" title="+"
                :key="'C' + option.id" />
            </div>
          </li>
        </ul>
      </div>
      <div class="input-container">
        <form>
          <input-field :id="this.inputFields[0].id" :title="this.inputFields[0].title" :type="this.inputFields[0].type"
            :stageId="5" :listId="this.instanceId" :value="inputFields[0].value" :key="this.inputs_reload"></input-field>
          <input-field :id="this.inputFields[1].id" :title="this.inputFields[1].title" :type="this.inputFields[1].type"
            :stageId="5" :listId="this.instanceId" :value="inputFields[1].value" :key="this.inputs_reload"></input-field>
        </form>
      </div>
      <div class="editor-container">
        <text-editor title="Produktbeschreibung:" :value="this.inputFields[2].value" :id="inputFields[2].id"
          :listId="this.instanceId" :stageId="5" :key="this.inputs_reload"></text-editor>
      </div>
      <div class="upload-container">
        <Upload :ueberschrift="'Upload Produktbild:'" :stageId="5" :listId="this.instanceId" :id="4" :file="this.file"
          :key="this.inputs_reload" />
      </div>
      <div class="button-container">
        <one-button title="Weiteres Produkt hinzufügen" @click.prevent="IdPlusEins()"></one-button>
        <one-button title="Bearbeitung abschließen" type="button" v-on:click.prevent="askForMore()"></one-button>
      </div>
    </div>
  </base-card>
  <h1 v-if="fatalError">Keine Daten gefunden!</h1>
</template>

<script>
const stageNumber = 5;

import InputField from "../components/BasicComponents/InputField.vue";
import TextEditor from "../components/BasicComponents/TextEditor.vue";
import OneButton from '../components/BasicComponents/OneButton.vue';
import { useNavigationStore } from '../stores/navigation';
import BaseCard from '../components/BasicComponents/BaseCard.vue'
import Upload from "../components/FileUpload/Upload.vue";
import imageProductArticle from '../assets/desktop/search.svg';
import { useModelStore } from '../stores/model'
import ListButton from '../components/BasicComponents/ListButton.vue'
import { ApiNames, httpGet, httpPost } from '../libs/httpHelper'
import PencilSvg from '../assets/desktop/pencil.svg'
import Swal from 'sweetalert2';

export default {
  components: { InputField, TextEditor, OneButton, BaseCard, Upload, ListButton },
  data() {
    return {
      imageProductArticle: imageProductArticle,
      navigationStore: useNavigationStore(),
      modelStore: useModelStore(),
      instanceId: 1,
      inputs_reload: 0,
      hasItems: false,
      fatalError: false,
      pencilSvg: PencilSvg,
      file: {},
      lists: [
        {
          id: -1,
          sorting: -1,
          params: [{
            id: 0,
            type: "",
            title: "",
            value: "",
            valid: false
          }]
        }
      ],
      inputFields: [
        {
          id: 1,
          title: "Überschrift",
          type: "text",
          value: ""
        }, {
          id: 2,
          title: "Link",
          type: "url",
          value: ""
        }, {
          id: 3,
          title: "Editor",
          type: "text",
          value: ""
        }, {
          id: 4,
          title: "UploadImage",
          type: "text",
          value: ""
        }
      ]
    };
  },

  beforeMount() {
    if (!this.modelStore.model) {
      this.fatalError = true
    }
    if (!this.modelStore.checkValidModel())
      this.fatalError = true

    var currStage = this.modelStore.getStage(stageNumber)

    this.modelStore.setNextInstance(stageNumber)
    this.instanceId = currStage.currentInstance

  },

  mounted() {
    if (!this.modelStore.model)
      this.modelStore.newModel()

    this.navigationStore.step(stageNumber)
    this.navigationStore.items[stageNumber].active = true

    var currStage = this.modelStore.getStage(stageNumber)
    if (currStage) {
      if (this.instanceId < 2) {
        this.modelStore.fillStageParams(stageNumber, this.instanceId, this.inputFields)
      }
      if (this.instanceId > 1) {
        this.lists = this.modelStore.getStageLists(stageNumber, 1)
        this.sortTheLists()
        this.ShowListParams(this.instanceId - 1)
      }
    }
  },

  methods: {
    nextStep() {
      this.navigationStore.items[stageNumber].valid = true
      this.navigationStore.step(6)
      this.navigationStore.navigate(6)
    },

    IdPlusEins() {
      var currStage = this.modelStore.getStage(stageNumber)

      var list = this.modelStore.getListParams(stageNumber, this.instanceId)

      if (list.params[0].value !== "") {
        if (currStage) {
          this.instanceId = parseInt(currStage.currentInstance)
        }

        this.modelStore.setNextInstance(stageNumber)
        this.instanceId = currStage.currentInstance

        this.inputFields.forEach(field => {
          field.value = ""
        })
        this.modelStore.fillStageParams(stageNumber, this.instanceId, this.inputFields)
        this.lists = this.modelStore.getStageLists(stageNumber, 1)
        this.sortTheLists()

        this.reload();
      }
    },
    reload() {
      this.inputs_reload++
    },
    deleteItem(option) {
      if (this.modelStore.setListToDelete(stageNumber, option.id)) {
        this.lists = this.modelStore.getStageLists(stageNumber, 1)
        this.sortTheLists()
        this.instanceId = this.modelStore.getCurrentInstance(stageNumber)
        this.ShowListParams(this.instanceId)
      }
    },

    addItem(option) {
      if (this.modelStore.cancelListToDelete(stageNumber, option.id)) {
        this.lists = this.modelStore.getStageLists(stageNumber, 1)
        this.sortTheLists()
        this.instanceId = this.modelStore.getCurrentInstance(stageNumber)
        this.ShowListParams(this.instanceId)
      }
    },

    ShowListParams(option) {
      this.instanceId = parseInt(option)
      if (option.id) {
        this.instanceId = parseInt(option.id)
      } else {
        this.instanceId = option
      }
      var currStage = this.modelStore.getStage(stageNumber)
      if (currStage)
        currStage.currentInstance = this.instanceId

      var tempFile = {
        fileName: "",
        id: "",
        originalName: "",
        url: "",
      }

      var newList = []
      this.inputFields.forEach(prop => {
        newList.push({
          id: prop.id,
          title: prop.title,
          type: prop.type,
          value: "",
          isRequired: prop.isRequired
        })
        var dataProp = this.modelStore.getParamValue(stageNumber, this.instanceId, prop.id);
        if (prop.id === 4) {
          tempFile.fileName = prop.title
          tempFile.url = prop.value
          tempFile.id = prop.id
          tempFile.originalName = prop.title
        }
        if (dataProp)
          newList[newList.length - 1].value = dataProp
      });
      this.inputFields = newList;
      this.file = tempFile
    },
    askForMore() {
      var stageLists = this.modelStore.getStageLists(stageNumber, 1);
      var listCount = 0;

      stageLists.forEach((list) => {
        if (!list.toDelete)
          listCount++
      })

      var maxAnz = 5 - listCount

      var msgAnz = `<p>Sie haben nur ${listCount} ${listCount > 1 ? 'Produkte' : 'Produkt'} hinzugefügt.</p>
                    <p>Sie können noch ${maxAnz} ${maxAnz > 1 ? 'weitere Produkte' : 'weiteres Produkt'} eingeben.</p><br>
                    <p>Möchten Sie weitere Eingaben vornehmen?</p>`
      if (listCount >= 3) {
        this.postDataNextStep()
        return;
      } 
      else {
        Swal.fire({
          title: '',
          html: msgAnz,
          icon: 'question',
          confirmButtonText: 'Ja',
          confirmButtonColor: "#00a1e6",
          showDenyButton: true,
          denyButtonText: 'Nein',
          denyButtonColor: "#bbb",
        }).then((res) => {
          if (res.isConfirmed) {
            return;
          }
          if (res.isDenied) {
            this.postDataNextStep()
          }
        });
      }
    },

    // post and get then next step
    async postDataNextStep() {
      var model = this.modelStore.model;
      await httpPost(ApiNames.model_save, model);
      this.getData()
    },
    async getData() {

      var response = await httpGet(ApiNames.model_get, this.modelStore.model.id, this.modelStore.token)
      var data = response.data;
      this.modelStore.model = data;

      this.navigationStore.items[stageNumber].valid = true
      this.navigationStore.step(6)
      this.navigationStore.navigate(6)
    },

    // post and get then preview
    async postDataPreview() {
      var model = this.modelStore.model;
      await httpPost(ApiNames.model_save, model);
      this.getDataPreview()
    },

    async getDataPreview() {//async
      var response = await httpGet(ApiNames.model_get, this.modelStore.model.id, this.modelStore.token)
      var data = response.data;
      this.modelStore.model = data;
      this.modelStore.showPreview();

      this.lists = this.modelStore.getStageLists(stageNumber, 1)
      this.sortTheLists()
      var currStage = this.modelStore.getStage(stageNumber)
      this.instanceId = currStage.currentInstance
      this.ShowListParams(currStage.currentInstance)
    },

    sortTheLists() {
      this.lists.sort(function (a, b) { return a.sorting - b.sorting });
    }
  }

};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

form {
  width: 200%;
}

.base-card {
  width: 40vw;
  height: fit-content;
}

.product-article-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  height: 85%;
  margin: auto;
}

.input-container {
  position: relative;
  display: flex;
  width: 70.5%;
  flex-direction: column;
}

.upload-container {
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 1rem;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

#preview {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  margin-bottom: 1rem;
}

.cmp-list {
  margin-bottom: 1rem;
  list-style-type: none;
  max-height: 5rem;
  overflow-y: auto;
  border-radius: 4px;
  padding: 4px 0px;
  padding-left: 4px;
  box-shadow: var(--list-shadow);
}

.to_delete {
  text-decoration: line-through;
}

li {
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  margin-bottom: 2px;
}

li span {
  cursor: pointer;
}

li input:hover {
  transition: 0.2s;
  box-shadow: 2px 2px 5px var(--dark-gray);
  background-color: var(--mdblue-hover);
  border-color: var(--mdblue-hover);
}

li span:hover {
  color: var(--mdblue-hover);
}

.li-container {
  margin-bottom: .5rem;
}

.editor-container {
  height: 280px;
  margin-top: .5rem;
}

.del-btn {
  margin-right: .5rem;
}
</style>