<template>
    <main id="Main">
        <base-card class="base-card">
            <div class="welcome-container">
                <div class="text-container">
                    <h2 class="headText mb-2">Willkommen im Datentool von MDESIGN</h2>
                    <div>
                        Bitte loggen Sie sich, mit den von uns zugesandten Login-Daten, ein.
                        Über dieses Datentool können Sie anschließend einfach, schnell und unkompliziert ihr Firmen- und
                        Produktprofil erstellen.
                        Sollten Sie Unterstützung benötigen, stehen wir Ihnen gerne zur Seite.
                        MDESIGN Kundenbetreuung
                        +49 234 30703-71
                        marketing-services@mdesign.de
                    </div>
                </div>
                <div class="img-container">
                    <img src="@/assets/eyecatcher-small.jpg" alt="eye catcher" class="image" />
                </div>
                <div class="btn-container">
                    <one-button title="Anmelden" class="btn btn-primary" v-on:click="Login()">Anmelden</one-button>
                    <p v-if="this.errorBool" class="error">{{ this.error }}</p>
                </div>
            </div>
        </base-card>
    </main>
</template>

<script>
import { useNavigationStore } from '../../stores/navigation'
import BaseCard from '../BasicComponents/BaseCard.vue';
import OneButton from '../BasicComponents/OneButton.vue'
import { useModelStore } from '../../stores/model'
import { ApiNames, httpGet } from '../../libs/httpHelper' //httpGet, httpPost

export default {
    name: "Main",
    components:
        { BaseCard, OneButton },
    data() {
        return {
            navigationStore: useNavigationStore(),
            modelStore: useModelStore(),
            errorBool: false,
            error: "Fehler",
            uid: ""
        }
    },
    mounted() {
        this.modelStore.createStage(-1)
        this.uid = this.$route.fullPath.split('=')[1]
        this.uid = decodeURIComponent(this.uid)
        this.modelStore.newModel(this.uid)
    },
    methods: {
        Login() {
            document.getElementById('navBar').classList.remove('nodisplay')
            this.getData()
            this.navigationStore.navigate(0)
        },

        async getData() {
            var modelData = {
                id: "",
                version: 0,
                name: 0,
                progress: 0,
                stages: [],
            }
            modelData = this.modelStore.newModelInstance(this.uid)
            var response = await httpGet(ApiNames.model_get, modelData.id)//
            var data = response.data;
            this.modelStore.model = data;

            if (response) {
                this.navigationStore.navigate(0)
            }
        }
    }
}

</script>

<style scoped>
.base-card {
    width: 50vw;
    height: 75vh;
}

.welcome-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding: 2rem;
}

.img-container {
    display: flex;
    justify-content: center;
}

.btn-container {
    display: flex;
    justify-content: right;
}

div#text {
    justify-self: flex-start;
    grid-area: txt;

}

h2#headText {
    justify-self: flex-start;
    grid-area: header;

}

image {
    justify-self: flex-end;
    grid-area: image;
}

.btn {

    grid-area: btn;
    justify-self: flex-start;

}
</style>