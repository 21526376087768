<template>
    <div class="card-body">
        <div class="div-header">
            <div v-show="hasTitle" class="title-logo">
                <div class="logo">
                    <img :src="this.logo" alt="Logo" class="icon-white" />
                </div>
                <div class="title">
                    <p>{{ title }}</p>
                </div>
            </div>
            <div class="preview" v-show="hasPreview">
                <base-button @click.prevent="onClickButton()" title="Preview"></base-button>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import BaseButton from './OneButton.vue'

export default {
    components: { BaseButton },
    props: {
        title: {
            type: String,
            default: ""
        },
        hasTitle:
        {
            type: Boolean,
            default: false
        },
        hasPreview:
        {
            type: Boolean,
            default: false
        },
        logo: {
            type: String,
            default: ""
        }
    },
    methods: {
        onClickButton() {
            this.$emit('post')
        }
    }
}

</script>

<style scoped>
.card-body {
    background-color: #fff;
    width: 90vw;
    height: fit-content;
    /* max-height: 90vh; */
    margin: 1rem;
    margin-top: 1.5rem;
    box-sizing: border-box;
    border: none;
    border-radius: 15px;
    box-shadow: 2px 2px 15px var(--light-gray);
    padding: 1rem 0;
}

.title-logo {
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: fit-content;
    height: fit-content;
    padding: .5rem;
    margin: 0;
    top: -2rem;
    left: 1rem;
}

.logo {
    box-shadow: 2px 2px 5px var(--light-gray);
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    padding: .8rem;
    background-color: var(--mdblue);
    color: #fff;

}

.title {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: end;
    margin-left: 1rem;
    color: black;
    font-size: 1.4rem;
    /* border-bottom: 1px solid var(--light-gray); */
}

.icon-white {
    filter: invert(100%);
}

.preview {
    position: relative;
    max-width: fit-content;
    height: fit-content;
    right: 5%
}

.div-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
}
</style>