function upload(formData) {
  const photos = formData.getAll('photos');
  var name = ""
  const promises = photos.map((x) => getImage(x)
    .then(
      // Umlaute, Klammern und Leerzeichen müssen aus Bildnamen entfernt werden
        name = umwandelnString(x.name)
      )
    .then(img => ({
        id: img,
        originalName: name,
        fileName: name,
        url: img
      })));
  return Promise.all(promises)
}

function getImage(file) {
  return new Promise((resolve) => {
    const fReader = new FileReader();
    const img = document.createElement('img');
    fReader.onload = () => {
      img.src = fReader.result;
      resolve(getBase64Image(img));
    }

    fReader.readAsDataURL(file);
  })
}

function getBase64Image(img) {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  const dataURL = img.src;
  return dataURL;
}

function dataURLtoFile(dataurl, filename) {
  if (dataurl === "DEFAULT")
    return

  if (dataurl === undefined || filename === undefined)
    return

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function resizeImage(file, maxWidth, maxHeight, callback) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          if (width / maxWidth > height / maxHeight) {
            width = maxWidth;
            height = Math.round(maxWidth * img.height / img.width);
          } else {
            height = maxHeight;
            width = Math.round(maxHeight * img.width / img.height);
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(function (blob) {
          const scaledFile = new File([blob], file.name, { type: file.type });
          resolve(scaledFile);
        }, file.type);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  });
}

function umwandelnString(inputString) {
  // Sonderzeichen außer Umlaute und Punkt ersetzten
  var replacedString = inputString.replace(/[^A-Za-z0-9äöüÄÖÜßẞ.]/g, "_");
  // Umlaute ersetzen
  let umlauteErsetzt = replacedString.replace(/[äöüÄÖÜßẞ]/g, function(match) {
      return {
          'ä': 'ae',
          'ö': 'oe',
          'ü': 'ue',
          'Ä': 'AE',
          'Ö': 'OE',
          'Ü': 'UE',
          'ß': 'ss',
          'ẞ': 'Ss'
      }[match];
  });
  return umlauteErsetzt;
}
export { upload, dataURLtoFile, resizeImage }