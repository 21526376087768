<template>
  <div id="run">
    <Header :valueMenuMobile="valueMenuMobile" :methodMenuMobile="changeMenuMobile" />
    <MenuMobile v-show="valueMenuMobile" />
    <div id="main-content">
      <RouterView />
    </div>
    <Footer v-show="!valueMenuMobile" />
  </div>
</template>

<script>
import Header from './components/Header/Header.vue';
import Footer from './components/Footer/Footer.vue';
import MenuMobile from './components/MenuMobile/MenuMobile.vue';
import { RouterView } from 'vue-router';

export default {
  name: "App",
  components: {
    Header,
    Footer,
    MenuMobile,
    RouterView,
  },
  data() {
    return {
      valueMenuMobile: false,
      mediaQuerieList: matchMedia("(max-width: 822px)")
    }
  },
  methods: {
    changeMenuMobile() {
      this.valueMenuMobile = !this.valueMenuMobile;
    },
    changeValueResponsive(mediaQuerieList) {
      if (!mediaQuerieList.matches) {
        this.valueMenuMobile = false;
      }
    }
  },

  mounted() {
    this.changeValueResponsive(this.mediaQuerieList);
    this.mediaQuerieList.addEventListener("change", this.changeValueResponsive);
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        if (this.className != "link-social-media")
          document.querySelector(

            this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
            });
      });
    });
  },
  updated() {
    this.mediaQuerieList.addEventListener("change", this.changeValueResponsive);
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

body {
  background-color: var(--background-gray);
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: #000000;
  --light-gray: #c9c9c9;
  --dark-gray: hsl(0, 0%, 55%);
  --very-dark-gray: hsl(0, 0%, 41%);
  --gray: hsl(0, 0%, 50%);
  --mdblue: #00a1e6;
  --mdblue-hover: #32ade2;
  --box-shadow: 2px 2px 15px var(--light-gray);
  --list-shadow: 2px 2px 6px 1px var(--light-gray);
  --background-gray: #eeeeee;
  --btn-green: hsl(122, 39%, 49%);
  --btn-green-hover: #47a44c;
  --inp-is-active: #9c1cb0;
}

footer#Footer {
  width: 100%;
  height: 42px;
  background-color: var(--gray);
  justify-content: center;
  position: fixed;
}

#main-content {
  overflow-y: auto;
  overflow-x: hidden;
  /* max-height: 80vh; */
  padding-bottom: 60px;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  direction: row;
}

.mt-2 {
  margin-top: 2rem;
}

.mx-auto {
  display: block;
  width: auto;
}

.mb-2 {
  margin-bottom: 2rem;
}

.justify-center {
  justify-content: center;
  justify-self: center;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url(./fonts/roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/roboto/Roboto-Light.ttf);
}

div#run {
  display: flex;
  flex-direction: column;
  align-items: center;

}

div#content {

  display: flex;

}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

header {
  width: 100%;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: #6cf;
}

@media screen and (max-width: 498px) {
  div#content {
    flex-direction: column;
    justify-content: center;
  }
}
</style>
